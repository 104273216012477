import React,{Component} from "react";
import {Form } from "react-bootstrap";
import "./AgeCalculator.css";
import moment from "moment";
import * as common from "../../Common/Common";

interface AgeCalculatorProps{
  visitDate?:Date,
  dob?:Date
};
interface AgeCalculatorStates{};

export default class AgeCalculator extends React.Component<AgeCalculatorProps,AgeCalculatorStates>
{
  constructor(props:AgeCalculatorProps)
  {
    super(props);
  }

  render() 
  {
    let a = moment(this.props.visitDate).startOf(common.Units.day);
    let b = moment(this.props.dob).startOf(common.Units.day);
    // console.log(a);
    // console.log(b);
    let years = a.diff(b,common.Units.year);
    // console.log(years);
    b.add(years,common.Units.year);
    // console.log(b);
    let months = a.diff(b,common.Units.month);
    // console.log(months);
    b.add(months,common.Units.month);
    // console.log(b);
    let days = a.diff(b,common.Units.day);
    // console.log(days);

    return (
         <Form.Label>
            <span>{isNaN(years)?'': (years + "Y")} </span>
            <span>{isNaN(months)?'': months + "M"} </span>
            <span>{isNaN(days)?'': days + "D"}</span>
         </Form.Label>
    );

  }
}