import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import { Genders, VisitItem, GrowthChartResults } from "../../../Common/Common";
import { ChartListIndexEnum, ChartAxisNames, ChartItem, IChartMetaDataByGender, IChartFile, ChartLineTags, MappingFunctionNames, AxesSetupNames } from "../../../Common/Charts";
import React from "react";
import { PercentileZscoreCalculator } from "../GC";


type IDisplayChartListItemProps = {
    chartItem:ChartItem<ChartListIndexEnum,ChartAxisNames,ChartAxisNames>,
    // percentile_zScore:string,
    gender:Genders,
    visit:VisitItem,
    isSelected:boolean
  }
type IDisplayChartListItemStates = {
    percentile_zScore:string
}
//   function DisplayChartListItem(props:IChartListItem){
export class DisplayChartListItem extends React.Component<IDisplayChartListItemProps,IDisplayChartListItemStates>{
  private pzc:PercentileZscoreCalculator = new PercentileZscoreCalculator();

    constructor(props:IDisplayChartListItemProps){
        super(props);
        this.state = {
            percentile_zScore:""
        };
        
       
    }


    private  setChartMetaDataByGender(chartFile: IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>)
      : IChartMetaDataByGender<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,Genders,GrowthChartResults>|null
      {
         if(this.props.chartItem !== undefined 
            && this.props.gender !== 0 
            && chartFile !== undefined 
            )
            {
                let chartMetaDataByGender:IChartMetaDataByGender<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,Genders,GrowthChartResults> = {
                    source:chartFile.source,
                    meta : chartFile.meta,
                    gender : Genders.male,
                    data : chartFile[Genders.male]
                };
                if(this.props.gender == Genders.male)
                {
                    chartMetaDataByGender.gender = Genders.male;
                    chartMetaDataByGender.data = chartFile[Genders.male];
                }
                else if(this.props.gender == Genders.female)
                {
                    chartMetaDataByGender.gender = Genders.female;
                    chartMetaDataByGender.data = chartFile[Genders.female];
                }
                chartMetaDataByGender.meta.Title = chartFile.meta.Y_Axis.AXIS_Name +' For ' + chartFile.meta.X_Axis.AXIS_Name;
                return chartMetaDataByGender;

            }
            else{
                
               
            }
           return null;
    }

    private PercentileZscoreCalculatorFile()
    {
      this.props.chartItem.chartFile.then(chartFile => {
       
        this.pzc.SetChart(this.setChartMetaDataByGender(chartFile)!);
        this.CalculatePercentileZscore();
      });
    }
    private CalculatePercentileZscore()
    {
        let x = this.pzc.GetPercentile_ZScore_OfNative(this.props.visit);
        if(x !== this.state.percentile_zScore)
        {
          this.setState({percentile_zScore:x});      
        }
    }
    componentDidMount()
    {
       
         if(this.props.chartItem != undefined && this.props.isSelected == true)
         {
            this.PercentileZscoreCalculatorFile();
         }
    }
    componentDidUpdate(prevProps:IDisplayChartListItemProps) {
      if( (this.props.chartItem && this.props.isSelected == true)
      // || this.pzc.chart != undefined
      )
      {
        if(prevProps.chartItem.ChartID != this.props.chartItem.ChartID
          || prevProps.gender != this.props.gender
          )
          {
            this.PercentileZscoreCalculatorFile();
          }
          else if (this.pzc.chart == undefined)
          {
            this.PercentileZscoreCalculatorFile();
          }
        else{
            this.CalculatePercentileZscore();
        }
      }
      else if (this.state.percentile_zScore != "")
      {
         this.setState({percentile_zScore:''});
      }
    }


    render(){
    return (<Row className="noMargin" noGutters={true}>
      <Col xs={2} className="text-center small">
        {this.props.chartItem.Type}
        <Form.Text><span>{this.props.chartItem.ResultType}</span></Form.Text>
        
      </Col>
      <Col xs={8} className="text-center small">
        {this.props.chartItem.YAxis} vs {this.props.chartItem.XAxis}
        <Form.Text><span>{Math.floor(this.props.chartItem.MinAge/12)} - {Math.floor(this.props.chartItem.MaxAge/12)} Years</span></Form.Text>
      </Col>
      <Col xs={2} className="text-center small">
           {this.props.gender !=0 && this.state.percentile_zScore 
            && <Badge variant={(this.state.percentile_zScore.startsWith(">") || this.state.percentile_zScore.startsWith("<"))?"danger":"success"}>
                {this.state.percentile_zScore} 
            </Badge>}
      </Col>
    </Row>);
    // return <span></span>
  }
}
