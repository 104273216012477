import React from "react";
import Form from "react-bootstrap/Form";
import {Units,FastVisitIntents,GeneralUnitConverter} from "../../Common/Common";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";



interface VisitInputComponentProps {
  unit:Units,
  unitIntent:FastVisitIntents,
  value:number,
  valueIntent:FastVisitIntents
  updateParentState:(key:FastVisitIntents,value:any) => void,
  disabled?:boolean
}
interface VisitInputComponentStates {
 
}

export default class VisitInputComponent extends React.Component<VisitInputComponentProps,VisitInputComponentStates>
{
    constructor(props:VisitInputComponentProps) {
        super(props);
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
        this.handleSelectEvent = this.handleSelectEvent.bind(this);
      }

      handleChangeEvent = (event:any) => {
        this.props.updateParentState(this.props.valueIntent,event.target.value);
      }

      handleSelectEvent(eventKey:any,event?:any)
      {
        this.props.updateParentState(this.props.unitIntent,eventKey);
      };

      render() 
      {
        return (
            <Form.Group controlId={this.props.valueIntent.toString()} as={Row} size="sm">
                 <Col xs={12} sm={12} md={2}>
                  <Form.Label>{this.props.valueIntent.toString()}</Form.Label>
                </Col>

                <Col xs={12} sm={12} md={10}> 
                  <InputGroup className="mb-1" size="sm">
                      <DropdownButton
                          as={InputGroup.Prepend}
                          variant="outline-secondary"
                          title={this.props.unit.toString()}
                          id="input-group-dropdown-1"
                          onSelect={this.handleSelectEvent}>

                          {Object.keys(GeneralUnitConverter[this.props.unit]!)
                            .map((key,index) =>{
                              return  <Dropdown.Item eventKey={key} key={key}>
                              <i className="fa fa-check" aria-hidden="true"></i>
                              {key}
                              </Dropdown.Item>
                            })
                          }
                      </DropdownButton>
                      
                      <Form.Control type="numeric"  value={this.props.value.toString()} onChange={this.handleChangeEvent} disabled={this.props.disabled}/>
                  </InputGroup>
                  <Form.Text className="text-muted">
                          {Object.keys(GeneralUnitConverter[this.props.unit]!)
                                .filter((key) => key !== this.props.unit)
                                .map((key,index) =>{
                                  
                                  let toUnit:Units = Object.values(Units).filter(k => k == key)[0];
                                  return  <span key={key}>
                                            {GeneralUnitConverter[this.props.unit]![toUnit]! *this.props.value} {key}
                                        </span>
                                })
                              }
                  </Form.Text>
                </Col>
            </Form.Group>
        );
      };
};
