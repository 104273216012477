import {Genders,Units,ChartOwners,GrowthChartResults,VisitItem,WeightUnitConverter,LengthUnitConverter} from "./Common";

export enum ChartAxisNames{
    Length = "Length",
    Age = "Age",
    Weight = "Weight",
    Stature = "Stature",
    BMI ="BMI",
    Head="Head",
    Height="Height"
  } 
export type IChartAxisName = {[a in ChartAxisNames]:{
  AXIS_Name:a,
  AXIS_DSC:string,
  AXIS_Unit:Units,
}};

export const ChartAxisLookup:IChartAxisName = {
  [ChartAxisNames.Length]:{AXIS_Name:ChartAxisNames.Length,AXIS_DSC:"Length",AXIS_Unit:Units.cm}
  ,[ChartAxisNames.Age]:{AXIS_Name:ChartAxisNames.Age,AXIS_DSC:"Age",AXIS_Unit:Units.month}
  ,[ChartAxisNames.Weight]:{AXIS_Name:ChartAxisNames.Weight,AXIS_DSC:"Weight",AXIS_Unit:Units.kg}
  ,[ChartAxisNames.Stature]:{AXIS_Name:ChartAxisNames.Stature,AXIS_DSC:"Stature",AXIS_Unit:Units.cm}
  ,[ChartAxisNames.BMI]:{AXIS_Name:ChartAxisNames.BMI,AXIS_DSC:"Body Mass Index",AXIS_Unit:Units.kg_m2}
  ,[ChartAxisNames.Head]:{AXIS_Name:ChartAxisNames.Head,AXIS_DSC:"head circumference",AXIS_Unit:Units.cm}
  ,[ChartAxisNames.Height]:{AXIS_Name:ChartAxisNames.Height,AXIS_DSC:"Heigth",AXIS_Unit:Units.cm}
}

export enum ChartLineTags{
  P01,
  P1,
  P3,
  P5,
   P10,
   P15 ,
   P25, 
   P50,
   P75,
   P85,
   P90,
   P95 ,
   P97,
   P99,
   P999,

     SD5neg,
     SD4neg,
     SD3neg,
     SD2neg,
     SD1P5neg,
     SD1neg,
     SD0P5neg,
     SD0,
     SD0P5,
     SD1,
     SD1P5,
     SD2 ,
     SD3,
     SD4,
     SD5
  }

  export type IChartLineTagItem<k extends ChartLineTags> = {[r in GrowthChartResults]?:number} & {Tag:k} & {name:string};

  export type IChartLineTag = {[k in ChartLineTags]:IChartLineTagItem<k>};

  export const CharLineTagIndexLookup:IChartLineTag = {
    [ChartLineTags.P01]:{ Tag: ChartLineTags.P01, "name": "0.1st", [GrowthChartResults.percentile]: 0.1 }
    ,[ChartLineTags.P1]:{ Tag: ChartLineTags.P1, "name": "1st", [GrowthChartResults.percentile]: 1 }

    ,[ChartLineTags.P3]:{ Tag: ChartLineTags.P3, "name": "3rd", [GrowthChartResults.percentile]: 3 }
    , [ChartLineTags.P5]:{ Tag: ChartLineTags.P5, "name": "5th", [GrowthChartResults.percentile]: 5}
    , [ChartLineTags.P10]:{ Tag: ChartLineTags.P10, "name": "10th", [GrowthChartResults.percentile]: 10 }
    , [ChartLineTags.P15]:{ Tag: ChartLineTags.P15, "name": "15th", [GrowthChartResults.percentile]: 15 }
    , [ChartLineTags.P25]:{ Tag:ChartLineTags.P25, "name": "25th", [GrowthChartResults.percentile]: 25 }
    , [ChartLineTags.P50]:{ Tag: ChartLineTags.P50, "name": "50th", [GrowthChartResults.percentile]: 50 }
    , [ChartLineTags.P75]:{ Tag: ChartLineTags.P75, "name": "75th", [GrowthChartResults.percentile]: 75 }
    , [ChartLineTags.P85]:{ Tag: ChartLineTags.P85, "name": "85th", [GrowthChartResults.percentile]: 85 }
    , [ChartLineTags.P90]:{ Tag: ChartLineTags.P90, "name": "90th", [GrowthChartResults.percentile]: 90 }
    , [ChartLineTags.P95]:{ Tag: ChartLineTags.P95, "name": "95th", [GrowthChartResults.percentile]: 95 }
    , [ChartLineTags.P97]:{ Tag: ChartLineTags.P97, "name": "97th", [GrowthChartResults.percentile]: 97 }
    , [ChartLineTags.P99]:{ Tag: ChartLineTags.P99, "name": "99th", [GrowthChartResults.percentile]: 99 }
    , [ChartLineTags.P999]:{ Tag: ChartLineTags.P999, "name": "99.9th", [GrowthChartResults.percentile]: 99.9 }
    
    , [ChartLineTags.SD5neg]: { Tag: ChartLineTags.SD5neg, "name": "-5", [GrowthChartResults.zscore]: -5 }
    , [ChartLineTags.SD4neg]: { Tag: ChartLineTags.SD4neg, "name": "-4", [GrowthChartResults.zscore]: -4 }
    , [ChartLineTags.SD3neg]: { Tag: ChartLineTags.SD3neg, "name": "-3", [GrowthChartResults.zscore]: -3 }
    , [ChartLineTags.SD2neg]: { Tag: ChartLineTags.SD2neg, "name": "-2", [GrowthChartResults.zscore]: -2 }
    , [ChartLineTags.SD1P5neg]:{ Tag: ChartLineTags.SD1P5neg, "name": "-1.5", [GrowthChartResults.zscore]: -1.5 }
    , [ChartLineTags.SD1neg]:{ Tag: ChartLineTags.SD1neg, "name": "-1", [GrowthChartResults.zscore]: -1 }
    , [ChartLineTags.SD0P5neg]:{ Tag: ChartLineTags.SD0P5neg, "name": "-0.5", [GrowthChartResults.zscore]: -0.5 }
    , [ChartLineTags.SD0]:{ Tag: ChartLineTags.SD0, "name": "0", [GrowthChartResults.zscore]: 0}
    , [ChartLineTags.SD0P5]:{ Tag: ChartLineTags.SD0P5, "name": "0.5", [GrowthChartResults.zscore]: 0.5 }
    , [ChartLineTags.SD1]:{ Tag: ChartLineTags.SD1, "name": "1", [GrowthChartResults.zscore]: 1 }
    , [ChartLineTags.SD1P5]:{ Tag: ChartLineTags.SD1P5, "name": "1.5", [GrowthChartResults.zscore]: 1.5 }
    , [ChartLineTags.SD2]:{ Tag: ChartLineTags.SD2, "name": "2", [GrowthChartResults.zscore]: 2 }
    , [ChartLineTags.SD3]:{ Tag: ChartLineTags.SD3, "name": "3", [GrowthChartResults.zscore]: 3 }
    , [ChartLineTags.SD4]:{ Tag: ChartLineTags.SD4, "name": "4", [GrowthChartResults.zscore]: 4 }
    , [ChartLineTags.SD5]:{ Tag: ChartLineTags.SD5, "name": "5", [GrowthChartResults.zscore]: 5 }
  }

  
  export enum MappingFunctionNames{
    Bmifa = "Bmifa",
    hfa= "hfa",
    lfa="lfa",
    sfa="sfa",
    wfa="wfa",
    wfl="wfl"
  };

export type IMappingFunctionOutput  = {X:number,Y:number, DataID:number,ResultValue?:string};
export type IMappingFunctions = {[k in MappingFunctionNames]:(obj: VisitItem) => IMappingFunctionOutput};
export const MappingFunctionsLookup:IMappingFunctions = {
    [MappingFunctionNames.Bmifa]: function (obj:VisitItem):IMappingFunctionOutput {
        return {
            X:obj.age
            ,Y: obj.weight * WeightUnitConverter[obj.weightUnit || Units.kg][Units.kg] 
                / (obj.length * LengthUnitConverter[obj.lengthUnit || Units.cm][Units.cm] * obj.length * LengthUnitConverter[obj.lengthUnit || Units.cm][Units.cm]) 
                * 10000
            ,DataID: obj.visitID
        };
    },
    [MappingFunctionNames.hfa]: function (obj:VisitItem):IMappingFunctionOutput {
        return {
            X:obj.age
            , Y: obj.head * LengthUnitConverter[obj.headUnit || Units.cm][Units.cm]
            , DataID: obj.visitID};
    },
    [MappingFunctionNames.lfa]: function (obj:VisitItem):IMappingFunctionOutput {
        return {X:obj.age
            , Y: obj.length * LengthUnitConverter[obj.lengthUnit || Units.cm][Units.cm]
            , DataID: obj.visitID
        };
    },
    [MappingFunctionNames.sfa]: function (obj:VisitItem):IMappingFunctionOutput {
        return {
            X:obj.age
            ,Y: obj.length * LengthUnitConverter[obj.lengthUnit || Units.cm][Units.cm]
            ,DataID: obj.visitID
        };
    },
    [MappingFunctionNames.wfa]: function (obj:VisitItem):IMappingFunctionOutput {
        return {
            X:obj.age
            , Y: obj.weight * WeightUnitConverter[obj.weightUnit || Units.kg][Units.kg]
            ,DataID: obj.visitID};
    },
    [MappingFunctionNames.wfl]: function (obj:VisitItem):IMappingFunctionOutput {
        return {
            X:obj.length * LengthUnitConverter[obj.lengthUnit || Units.cm][Units.cm]
            , Y: obj.weight * WeightUnitConverter[obj.weightUnit || Units.kg][Units.kg]
            ,DataID: obj.visitID
        };
    }
};

export enum AxesSetupNames{
  who_Bmifa_0_2="who_Bmifa_0_2",
  who_Bmifa_2_5="who_Bmifa_2_5",
  who_Bmifa_5_19="who_Bmifa_5_19",
  who_hfa_0_5="who_hfa_0_5",
  who_lfa_5_19="who_lfa_5_19",
  who_lfa_2_5="who_lfa_2_5",
  who_lfa_0_2="who_lfa_0_2",
  who_wfa_0_5="who_wfa_0_5",
  who_wfa_5_10="who_wfa_5_10",
  who_wfl_0_2="who_wfl_0_2",
  who_wfl_2_5="who_wfl_2_5",
  cdc_Bmifa_2_20="cdc_Bmifa_2_20",
  cdc_hfa_0_3="cdc_hfa_0_3",
  cdc_lfa_0_3="cdc_lfa_0_3",
  cdc_sfa_2_20="cdc_sfa_2_20",
  cdc_wfa_0_3="cdc_wfa_0_3",
  cdc_wfa_2_20="cdc_wfa_2_20",
  cdc_wfl_0_3="cdc_wfl_0_3",

}

export type IAxisSetup = {
  PrimaryEvery:number,
  SecondaryEvery:any,
  TickFactor:number,
  Unit:Units,
  HidenEvery?:{}
  Text?(d:number):number
};


export type IAxesSetupName = {
  [k in AxesSetupNames]:{  
    xAxis:IAxisSetup,
    xAxisAlternative:IAxisSetup
    yAxis:IAxisSetup,
    yAxisAlternative:IAxisSetup,
    axesSetupName:k
  }
}
export function AgeFormatter (d:number) {
  //switch (d) {
  //    case 0: return 'Birth';
  //    case 1: return '1 Year';
  //    default: return d + " Years";
  //}

  return d;
};
export const AxesSetupsLookup:IAxesSetupName = {
  [AxesSetupNames.who_Bmifa_0_2]: {
      xAxis: { PrimaryEvery: 1, SecondaryEvery: { 0.5: 1 }, TickFactor: 2, Unit: Units.month, Text: function (d:number) { return (d % 12); } }
      ,xAxisAlternative: { PrimaryEvery: 1, SecondaryEvery: { 0.25: 1, 0.5: 1, 0.75: 1 }, TickFactor: 12, Unit: Units.year, Text: AgeFormatter }

      ,yAxis: { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 5, Unit: Units.kg_m2 }
      , yAxisAlternative: { PrimaryEvery: 10, SecondaryEvery: { 5:1 },TickFactor:1,Unit:Units.lb_inch }
      ,axesSetupName:AxesSetupNames.who_Bmifa_0_2
     
  }
  , [AxesSetupNames.who_Bmifa_2_5]: {
      xAxis: { PrimaryEvery: 1, SecondaryEvery: { .5: 1 }, TickFactor: 2, Unit: Units.month, Text: function (d:number) { return d % 12; } }
      , xAxisAlternative: { PrimaryEvery: 1, SecondaryEvery: { 0.25: 1, 0.5: 1, 0.75: 1 }, TickFactor: 12, Unit: Units.year, Text: AgeFormatter }

     , yAxis: { PrimaryEvery: 1, SecondaryEvery: { 1: 1 }, TickFactor: 5, Unit: Units.kg_m2 }
     , yAxisAlternative: { PrimaryEvery: 10, SecondaryEvery: { 5:1 },TickFactor:1,Unit:Units.lb_inch  }
     ,axesSetupName:AxesSetupNames.who_Bmifa_2_5
     
  }
  , [AxesSetupNames.who_Bmifa_5_19]: {
      xAxis: { PrimaryEvery: 6, SecondaryEvery: { 3: 1, 9: 1 }, TickFactor: 1, Unit: Units.month, Text: function (d:number) { return d % 12; } }
      , xAxisAlternative: { PrimaryEvery: 1, SecondaryEvery: { 0.5: 1 }, TickFactor: 2, Unit: Units.year, Text: function (d:number) { return d; } }
      //, 'xAxisAlternative': { PrimaryEvery: 1, SecondaryEvery: { 0.25: 1, 0.5: 1, 0.75: 1 }, TickFactor: 12, Unit: Units.month }
     , yAxis: { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 1, Unit: Units.kg_m2 }
     , yAxisAlternative: { PrimaryEvery: 20, SecondaryEvery: { 10:1 },TickFactor:0.5,Unit:Units.lb_inch  }
     ,axesSetupName:AxesSetupNames.who_Bmifa_5_19
  }
  , [AxesSetupNames.who_hfa_0_5]: {
      'xAxis': { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 1, Unit: Units.month, Text: function (d:number) { return d % 12; } }
      , 'xAxisAlternative': { PrimaryEvery: 1, SecondaryEvery: { 0.25: 1, 0.5: 1, 0.75: 1 }, TickFactor: 12, Unit: Units.year, Text: AgeFormatter }

     , "yAxis": { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 5, Unit: Units.cm }
      , "yAxisAlternative": { PrimaryEvery: 1, SecondaryEvery: { 0.5: 1 }, HidenEvery: {}, TickFactor: 6, Unit: Units.inch }
      ,axesSetupName:AxesSetupNames.who_hfa_0_5
  }
  , [AxesSetupNames.who_lfa_5_19]: {
      'xAxis': { PrimaryEvery: 6, SecondaryEvery: { 3: 1, 9: 1 }, TickFactor: 1, Unit: Units.month, Text: function (d:number) { return d % 12; } }
      , 'xAxisAlternative': { PrimaryEvery: 1, SecondaryEvery: { 0.5: 1 }, TickFactor: 2, Unit: Units.year, Text: function (d:number) { return d; } }

      , "yAxis": { PrimaryEvery: 10, SecondaryEvery: { 5: 1 }, TickFactor: 1, Unit: Units.cm }
      , "yAxisAlternative": { PrimaryEvery: 4, SecondaryEvery: { 2: 1 }, TickFactor: 1, Unit: Units.inch }
      ,axesSetupName:AxesSetupNames.who_lfa_5_19
  }
  , [AxesSetupNames.who_lfa_2_5]: {
      'xAxis': { PrimaryEvery: 1, SecondaryEvery: { .5: 1 }, TickFactor: 2, Unit: Units.month, Text: function (d:number) { return d % 12; } }
      , 'xAxisAlternative': { PrimaryEvery: 1, SecondaryEvery: { 0.25: 1, 0.5: 1, 0.75: 1 }, TickFactor: 12, Unit: Units.year, Text: AgeFormatter }

      , "yAxis": { PrimaryEvery: 5, SecondaryEvery: { 2: 1, 3: 1 }, TickFactor: 1, Unit: Units.cm }
      , "yAxisAlternative": { PrimaryEvery: 2, SecondaryEvery: { 1: 1, 0.5: 1, 1.5: 1 }, TickFactor: 1, Unit: Units.inch }
      ,axesSetupName:AxesSetupNames.who_lfa_2_5
  }
  , [AxesSetupNames.who_lfa_0_2]: {
      'xAxis': { PrimaryEvery: 1, SecondaryEvery: { 0.5: 1 }, TickFactor: 2, Unit: Units.month, Text: function (d:number) { return d % 12; } }
      , 'xAxisAlternative': { PrimaryEvery: 1, SecondaryEvery: { 0.25: 1, 0.5: 1, 0.75: 1 }, TickFactor: 12, Unit: Units.year, Text: AgeFormatter }

      , "yAxis": { PrimaryEvery: 5, SecondaryEvery: { 2: 1, 3: 1 }, TickFactor: 1, Unit: Units.cm }
      , "yAxisAlternative": { PrimaryEvery: 2, SecondaryEvery: { 1: 1, 0.5: 1, 1.5: 1 }, TickFactor: 1, Unit: Units.inch }
      ,axesSetupName:AxesSetupNames.who_lfa_0_2
  }
  , [AxesSetupNames.who_wfa_0_5]: {
      'xAxis': { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 1, Unit: Units.month, Text: function (d:number) { return d % 12; } }
      , 'xAxisAlternative': { PrimaryEvery: 1, SecondaryEvery: { 0.25: 1, 0.5: 1, 0.75: 1 }, TickFactor: 12, Unit: Units.year, Text: AgeFormatter }
      , "yAxis": { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 1, Unit: Units.kg }
      , "yAxisAlternative": { PrimaryEvery: 4, SecondaryEvery: { 2: 1 }, TickFactor: 1, Unit: Units.lb }
      ,axesSetupName:AxesSetupNames.who_wfa_0_5
  }
  , [AxesSetupNames.who_wfa_5_10]: {
      'xAxis': { PrimaryEvery: 4, SecondaryEvery: { 2: 1 }, TickFactor: 1, Unit: Units.month, Text: function (d:number) { return d % 12; } }
      , 'xAxisAlternative': { PrimaryEvery: 1, SecondaryEvery: { 0.5: 1 }, TickFactor: 2, Unit: Units.year, Text: function (d:number) { return d; } }

      , "yAxis": { PrimaryEvery: 4, SecondaryEvery: { 2: 1 }, TickFactor: 1, Unit: Units.kg }
      , "yAxisAlternative": { PrimaryEvery: 8, SecondaryEvery: { 4: 1 }, TickFactor: 1, Unit: Units.lb }
      ,axesSetupName:AxesSetupNames.who_wfa_5_10
  }
  , [AxesSetupNames.who_wfl_0_2]: {
      'xAxis': { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 1, Unit: Units.cm }
      , 'xAxisAlternative': { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 2, Unit: Units.inch }

      , "yAxis": { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 1, Unit: Units.kg }
      , "yAxisAlternative": { PrimaryEvery: 4, SecondaryEvery: { 2: 1 }, TickFactor: 1, Unit: Units.lb }
      ,axesSetupName:AxesSetupNames.who_wfl_0_2
  }
  , [AxesSetupNames.who_wfl_2_5]: {
      'xAxis': { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 2, Unit: Units.cm }
      , 'xAxisAlternative': { PrimaryEvery: 1, SecondaryEvery: { 0.5: 1 }, TickFactor: 6, Unit: Units.inch }

      , "yAxis": { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 1, Unit: Units.kg }
      , "yAxisAlternative": { PrimaryEvery: 4, SecondaryEvery: { 2: 1 }, TickFactor: 1, Unit: Units.lb }
      ,axesSetupName:AxesSetupNames.who_wfl_2_5
  }

  , [AxesSetupNames.cdc_Bmifa_2_20]: {
      'xAxis': { PrimaryEvery: 6, SecondaryEvery: { 3: 1, 9: 1 }, TickFactor: 1, Unit: Units.month, Text: function (d:number) { return d % 12; } }
      , 'xAxisAlternative': { PrimaryEvery: 1, SecondaryEvery: { 0.5: 1 }, TickFactor: 2, Unit: Units.year, Text: function (d:number) { return d; } }

     , "yAxis": { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 1, Unit: Units.kg_m2 }
     , yAxisAlternative: { PrimaryEvery: 20, SecondaryEvery: { 10:1 },TickFactor:0.5,Unit:Units.lb_inch  }
     ,axesSetupName:AxesSetupNames.cdc_Bmifa_2_20
  }
  , [AxesSetupNames.cdc_hfa_0_3]: {
      'xAxis': { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 1, Unit: Units.month, Text: function (d:number) { return d % 12; } }
      , 'xAxisAlternative': { PrimaryEvery: 1, SecondaryEvery: { 0.25: 1, 0.5: 1, 0.75: 1 }, TickFactor: 12, Unit: Units.year, Text: AgeFormatter }

     , yAxis: { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 5, Unit: Units.cm }
      , "yAxisAlternative": { PrimaryEvery: 1, SecondaryEvery: { 0.5: 1 }, HidenEvery: {}, TickFactor: 4, Unit: Units.inch }
      ,axesSetupName:AxesSetupNames.cdc_hfa_0_3
  }
  , [AxesSetupNames.cdc_lfa_0_3]: {
      xAxis: { PrimaryEvery: 1, SecondaryEvery: { 0.5: 1 }, TickFactor: 2, Unit: Units.month, Text: function (d:number) { return d % 12; } }
      , xAxisAlternative: { PrimaryEvery: 1, SecondaryEvery: { 0.25: 1, 0.5: 1, 0.75: 1 }, TickFactor: 12, Unit: Units.year, Text: AgeFormatter }

       ,yAxis: { PrimaryEvery: 5, SecondaryEvery: { 2: 1, 3: 1 }, TickFactor: 1, Unit: Units.cm }
      ,yAxisAlternative: { PrimaryEvery: 2, SecondaryEvery: { 1: 1, 0.5: 1, 1.5: 1 }, TickFactor: 1, Unit: Units.inch }
      ,axesSetupName:AxesSetupNames.cdc_lfa_0_3
  }
  , [AxesSetupNames.cdc_sfa_2_20]: {
      xAxis: { PrimaryEvery: 6, SecondaryEvery: { 3: 1, 9: 1 }, TickFactor: 1, Unit: Units.month, Text: function (d:number) { return d % 12; } }
      , xAxisAlternative: { PrimaryEvery: 1, SecondaryEvery: { 0.5: 1 }, TickFactor: 2, Unit: Units.year, Text: function (d:number) { return d; } }

      , yAxis: { PrimaryEvery: 10, SecondaryEvery: { 5: 1 }, TickFactor: 1, Unit: Units.cm }
      , yAxisAlternative: { PrimaryEvery: 4, SecondaryEvery: { 2: 1 }, TickFactor: 1, Unit: Units.inch }
      ,axesSetupName:AxesSetupNames.cdc_sfa_2_20
  }
  , [AxesSetupNames.cdc_wfa_0_3]: {
      'xAxis': { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 1, Unit: Units.month, Text: function (d:number) { return d % 12; } }
      , 'xAxisAlternative': { PrimaryEvery: 1, SecondaryEvery: { 0.25: 1, 0.5: 1, 0.75: 1 }, TickFactor: 12, Unit: Units.year, Text: AgeFormatter }

      , "yAxis": { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 1, Unit: Units.kg }
      , "yAxisAlternative": { PrimaryEvery: 4, SecondaryEvery: { 2: 1 }, TickFactor: 1, Unit: Units.lb }
      ,axesSetupName:AxesSetupNames.cdc_wfa_0_3
  }
  , [AxesSetupNames.cdc_wfa_2_20]: {
      'xAxis': { PrimaryEvery: 6, SecondaryEvery: { 3: 1, 9: 1 }, TickFactor: 1, Unit: Units.month, Text: function (d:number) { return d % 12; } }
      , 'xAxisAlternative': { PrimaryEvery: 1, SecondaryEvery: { 0.5: 1 }, TickFactor: 2, Unit: Units.year, Text: function (d:number) { return d; } }

      , "yAxis": { PrimaryEvery: 5, SecondaryEvery: { 3: 1 }, TickFactor: 1, Unit: Units.kg }
      , "yAxisAlternative": { PrimaryEvery: 20, SecondaryEvery: {10:1 }, TickFactor: 0.5, Unit: Units.lb }
      ,axesSetupName:AxesSetupNames.cdc_wfa_2_20
  }
  , [AxesSetupNames.cdc_wfl_0_3]: {
      'xAxis': { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 1, Unit: Units.cm }
      , 'xAxisAlternative': { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 2, Unit: Units.inch }

      , "yAxis": { PrimaryEvery: 2, SecondaryEvery: { 1: 1 }, TickFactor: 1, Unit: Units.kg }
      , "yAxisAlternative": { PrimaryEvery: 4, SecondaryEvery: { 2: 1 }, TickFactor: 1, Unit: Units.lb }
      ,axesSetupName:AxesSetupNames.cdc_wfl_0_3
  }

};

export type IChartMetaData<X extends ChartAxisNames,Y extends ChartAxisNames, Tags extends  ChartLineTags, mappingFunctionId extends MappingFunctionNames
  ,axesSetupsName extends AxesSetupNames,outputResult extends GrowthChartResults> = {
  lines:Array<Tags>,
      Title:string,
      X_Axis:IChartAxisName[X],
      Y_Axis:IChartAxisName[Y],
      OutputResult: outputResult,
      AxesSetup:IAxesSetupName[axesSetupsName],
      MapFunction:IMappingFunctions[mappingFunctionId],
};
export type IChartGenderData<X extends ChartAxisNames, gender extends Genders, Tags extends ChartLineTags> = {
  data:{[t in Tags|X|'L'|'M'|'S']?:number}[];
};
export type IChartFile<X extends ChartAxisNames,Y extends ChartAxisNames, 
Tags extends  ChartLineTags,mappingFunctionId extends MappingFunctionNames,
axesSetupsName extends AxesSetupNames,outputResult extends GrowthChartResults> = 
{
  source:string[],
  meta:IChartMetaData<X,Y,Tags,mappingFunctionId,axesSetupsName,outputResult>,

  //  male:{[a:number]:{[t in Tags|X]:number}}[]
    [Genders.male]:IChartGenderData<X,Genders.male,Tags>,
    [Genders.female]:IChartGenderData<X,Genders.female,Tags>,
};

export type IChartMetaDataByGender<X extends ChartAxisNames,
    Y extends ChartAxisNames, 
    Tags extends  ChartLineTags,
    mappingFunctionId extends MappingFunctionNames,
    axesSetupsName extends AxesSetupNames,
    gender extends Genders
    ,outputResult extends GrowthChartResults> = 
{
    source:string[],
    meta:IChartMetaData<X,Y,Tags,mappingFunctionId,axesSetupsName,outputResult>,
    gender:gender,
   
    data?:IChartGenderData<X,gender,Tags>,
};


export type ChartItem <K extends ChartListIndexEnum, xAxis extends ChartAxisNames, yAxis extends ChartAxisNames> = {
  ChartID:K,
  ChartName:string,
  Type:ChartOwners,
  // Gender:Genders,
  MinAge:number,
  MaxAge:number,
  chartFile:Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>>,
  ResultType:GrowthChartResults,

  XAxis:xAxis,
  YAxis:yAxis
};



export interface IChartSelection{
  chartList: ChartItem <ChartListIndexEnum,ChartAxisNames,ChartAxisNames>[],
  selectedChartItem: ChartItem<ChartListIndexEnum,ChartAxisNames,ChartAxisNames>,
  chartType:ChartOwners,
  chartResultType:GrowthChartResults,
  profile:{
    gender:Genders
  },
  visits:VisitItem[]
};


export enum ChartListIndexEnum {
  cdc_lfa_0_to_3_percentiles,
   cdc_sfa_2_to_20_percentiles,
   cdc_wfa_0_to_3_percentiles,
    cdc_wfa_2_to_20_percentiles,
    cdc_hfa_0_to_3_percentiles,
    cdc_wfl_0_to_3_percentiles,
   cdc_Bmifa_2_to_20_percentiles,
  
  who_hfa_0_to_5_percentiles,
  who_lfa_0_to_2_percentiles,
   who_lfa_2_to_5_percentiles,
   who_wfa_0_to_5_percentiles,
   who_wfl_0_to_2_percentiles,
   who_wfl_2_to_5_percentiles,
   who_Bmifa_0_to_2_percentiles,
   who_Bmifa_2_to_5_percentiles,
   who_Bmifa_5_to_19_percentiles,
   who_wfa_5_to_10_percentiles,
   who_lfa_5_to_19_percentiles,

   who_hfa_0_to_5_zScore,
   who_Bmifa_0_to_2_zScore,
   who_Bmifa_2_to_5_zScore,
   who_Bmifa_5_to_19_zScore,
   who_lfa_0_to_2_zScore,
   who_lfa_2_to_5_zScore,
   who_lfa_5_to_19_zScore,
   who_wfa_0_to_5_zScore,
   who_wfa_5_to_10_zScore,
   who_wfl_0_to_2_zScore,
   who_wfl_2_to_5_zScore,

    cdc_lfa_0_to_3_zScore,
    cdc_sfa_2_to_20_zScore,
    cdc_wfa_0_to_3_zScore,
    cdc_wfa_2_to_20_zScore,
    cdc_hfa_0_to_3_zScore,
    cdc_wfl_0_to_3_zScore,
    cdc_Bmifa_2_to_20_zScore
}

