import React from "react";
import Form from "react-bootstrap/Form";
import {Genders,FastVisitIntents} from "../../Common/Common";
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {IoMdFemale,IoMdMale} from "react-icons/io"
interface GenderProps{
    gender:Genders,
    updateParentState:(key:FastVisitIntents,value:any) => void
}
interface GenderStates{
    // gender:any
}

export default class GenderComponent extends React.Component<GenderProps,GenderStates>
{
    constructor(props:GenderProps) {
        super(props);
        this.state = {gender: 0};
        this.handlerGenderChange = this.handlerGenderChange.bind(this);
      }

    handlerGenderChange = (value:any) =>{
        // this.setState({
        //     gender:value
        // });
        this.props.updateParentState(FastVisitIntents.gender,value);
      };

      render() 
      {
        return (<Form.Group controlId="Gender" as={Row} size="sm">
            <Col xs={12} sm={12} md={2}>
                <Form.Label>Gender</Form.Label>
            </Col>
            <Col xs={12} sm={12} md={10}> 
                <Form.Row>
                    <ToggleButtonGroup type="radio" 
                        name="gender" 
                        id="gender"
                        value={this.props.gender}
                        onChange={this.handlerGenderChange}
                        size="sm">
                        <ToggleButton value={Genders.male} variant={(this.props.gender===Genders.male && "primary")||"secondary"}>
                           <IoMdMale></IoMdMale> Male
                        </ToggleButton>
                        <ToggleButton value={Genders.female} variant={(this.props.gender===Genders.female && "primary")||"secondary"}>
                            <IoMdFemale></IoMdFemale> Female</ToggleButton>
                    </ToggleButtonGroup>
                </Form.Row>
            </Col>
        </Form.Group>);
      };
};