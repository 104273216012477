import {ChartOwners, GrowthChartResults} from "../../Common/Common";
import {ChartItem, ChartListIndexEnum, ChartAxisNames, IChartFile, ChartLineTags, AxesSetupNames, MappingFunctionNames} from "../../Common/Charts";
import mergeDeep from "../../Common/Merge";

// import cdc_bmi_all from "./CDC/Percentiles/cdc_Bmifa_all_2_to_20_percentilesGC";
// import cdc_hfa_all from "./CDC/Percentiles/cdc_hfa_all_0_to_3_percentilesGC";
//  import cdc_lfa_all from "./CDC/Percentiles/cdc_lfa_all_0_to_3_percentilesGC";
//  import cdc_sfa_all from "./CDC/Percentiles/cdc_sfa_all_2_to_20_percentilesGC";
//  import cdc_wfa_2_20 from "./CDC/Percentiles/cdc_wfa_all_2_to_20_percentilesGC";
//  import cdc_wfa_0_3 from "./CDC/Percentiles/cdc_wfa_all_0_to_3_percentilesGC";
//  import cdc_wfl_all from "./CDC/Percentiles/cdc_wfl_all_0_to_3_percentilesGC";
// import {cdc_Bmifa_2_to_20_zScore} from "./CDC/zScore/cdc_Bmifa_all_2_to_20_zScoreGC";
// import {cdc_hfa_0_3_zScore} from "./CDC/zScore/cdc_hfa_all_0_to_3_zScoreGC"
// import {cdc_lfa_0_3_zScore} from "./CDC/zScore/cdc_lfa_all_0_to_3_zScoreGC"
// import {cdc_sfa_2_20_zScore} from "./CDC/zScore/cdc_sfa_all_2_to_20_zScoreGC"
// import {cdc_wfa_0_3_zScore} from "./CDC/zScore/cdc_wfa_all_0_to_3_zScoreGC"
// import {cdc_wfa_2_20_zScore} from "./CDC/zScore/cdc_wfa_all_2_to_20_zScoreGC"
// import {cdc_wfl_0_3_zScore} from "./CDC/zScore/cdc_wfl_all_0_to_3_zScoreGC"
// import {who_Bmifa_0_to_2_percentiles} from "./WHO/Percentiles/who_Bmifa_all_0_to_2_percentilesGC"
// import {who_Bmifa_2_to_5_percentiles} from "./WHO/Percentiles/who_Bmifa_all_2_to_5_percentilesGC"
// import {who_Bmifa_5_to_19_percentiles} from "./WHO/Percentiles/who_Bmifa_all_5_to_19_percentilesGC"
// import {who_hfa_0_to_5_percentiles} from "./WHO/Percentiles/who_hfa_all_0_to_5_percentilesGC"
// import {who_lfa_0_to_2_percentiles} from "./WHO/Percentiles/who_lfa_all_0_to_2_percentilesGC"
// import {who_lfa_2_to_5_percentiles} from "./WHO/Percentiles/who_lfa_all_2_to_5_percentilesGC"
// import {who_lfa_5_to_19_percentiles} from "./WHO/Percentiles/who_lfa_all_5_to_19_percentilesGC"
// import {who_wfa_0_to_5_percentiles} from "./WHO/Percentiles/who_wfa_all_0_to_5_percentilesGC"
// import {who_wfa_5_to_10_percentiles} from "./WHO/Percentiles/who_wfa_all_5_to_10_percentilesGC"
// import {who_wfl_0_to_2_percentiles} from "./WHO/Percentiles/who_wfl_all_0_to_2_percentilesGC"
// import {who_wfl_2_to_5_percentiles} from "./WHO/Percentiles/who_wfl_all_2_to_5_percentilesGC"


// import {who_Bmifa_0_to_2_zScore} from "./WHO/zScore/who_Bmifa_all_0_to_2_zScoreGC";
// import {who_Bmifa_2_to_5_zScore} from "./WHO/zScore/who_Bmifa_all_2_to_5_zScoreGC";
// import {who_Bmifa_5_to_19_zScore} from "./WHO/zScore/who_Bmifa_all_5_to_19_zScoreGC";
// import {who_hfa_0_to_5_zScore} from "./WHO/zScore/who_hfa_all_0_to_5_zScoreGC";
// import {who_lfa_0_to_2_zScore} from "./WHO/zScore/who_lfa_all_0_to_2_zScoreGC";
// import {who_lfa_2_to_5_zScore} from "./WHO/zScore/who_lfa_all_2_to_5_zScoreGC";
// import {who_lfa_5_to_19_zScore} from "./WHO/zScore/who_lfa_all_5_to_19_zScoreGC";
// import {who_wfa_0_to_5_zScore} from "./WHO/zScore/who_wfa_all_0_to_5_zScoreGC";
// import {who_wfa_5_to_10_zScore} from "./WHO/zScore/who_wfa_all_5_to_10_zScore";
// import {who_wfl_0_to_2_zScore} from "./WHO/zScore/who_wfl_all_0_to_2_zScore";
// import {who_wfl_2_to_5_zScore} from "./WHO/zScore/who_wfl_all_2_to_5_zScore";


type ChartListIndex = {[k in ChartListIndexEnum]: ChartItem<k,ChartAxisNames,ChartAxisNames>};

export const ChartListLookup:ChartListIndex = {
  //  [ChartListIndexEnum.cdc_lfa_0_to_3_percentiles] : { ChartID: ChartListIndexEnum.cdc_lfa_0_to_3_percentiles, ChartName: "cdc_lfa_0_to_3_percentiles",MinAge: 0, MaxAge: 35.5 ,chartFile:cdc_lfa_all, Type: ChartOwners.CDC, XAxis: ChartAxisNames.Age, YAxis: ChartAxisNames.Length, ResultType:GrowthChartResults.percentile }
  // , [ChartListIndexEnum.cdc_sfa_2_to_20_percentiles] : { ChartID: ChartListIndexEnum.cdc_sfa_2_to_20_percentiles, ChartName: "cdc_sfa_2_to_20_percentiles", MinAge: 24, MaxAge: 240, chartFile:cdc_sfa_all, Type: ChartOwners.CDC,XAxis: ChartAxisNames.Age, YAxis: ChartAxisNames.Length, ResultType: GrowthChartResults.percentile }
  // , [ChartListIndexEnum.cdc_wfa_0_to_3_percentiles]:{ ChartID: ChartListIndexEnum.cdc_wfa_0_to_3_percentiles, ChartName: "cdc_wfa_0_to_3_percentiles",  MinAge: 0, MaxAge: 36, chartFile:cdc_wfa_0_3, Type: ChartOwners.CDC, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Weight, ResultType: GrowthChartResults.percentile }
  // , [ChartListIndexEnum.cdc_wfa_2_to_20_percentiles]:{ ChartID: ChartListIndexEnum.cdc_wfa_2_to_20_percentiles, ChartName: "cdc_wfa_2_to_20_percentiles", MinAge: 24, MaxAge: 240, chartFile:cdc_wfa_2_20, Type: ChartOwners.CDC, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Weight, ResultType: GrowthChartResults.percentile }
  // ,[ChartListIndexEnum.cdc_hfa_0_to_3_percentiles]:{ ChartID: ChartListIndexEnum.cdc_hfa_0_to_3_percentiles, ChartName: "cdc_hfa_0_to_3_percentiles", MinAge: 0, MaxAge: 36, chartFile:cdc_hfa_all, Type: ChartOwners.CDC, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Head, ResultType: GrowthChartResults.percentile }
  // , [ChartListIndexEnum.cdc_wfl_0_to_3_percentiles]:{ ChartID: ChartListIndexEnum.cdc_wfl_0_to_3_percentiles, ChartName: "cdc_wfl_0_to_3_percentiles",  MinAge: 0, MaxAge: 36, chartFile:cdc_wfl_all, Type: ChartOwners.CDC, XAxis: ChartAxisNames.Length, "YAxis": ChartAxisNames.Weight, ResultType: GrowthChartResults.percentile }
  // ,[ChartListIndexEnum.cdc_Bmifa_2_to_20_percentiles]:{ ChartID: ChartListIndexEnum.cdc_Bmifa_2_to_20_percentiles, ChartName: "cdc_Bmifa_2_to_20_percentiles", MinAge: 24, MaxAge: 240.5, chartFile:cdc_bmi_all, Type: ChartOwners.CDC, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.BMI, ResultType: GrowthChartResults.percentile }  
  

  [ChartListIndexEnum.cdc_lfa_0_to_3_percentiles] : { ChartID: ChartListIndexEnum.cdc_lfa_0_to_3_percentiles, ChartName: "cdc_lfa_0_to_3_percentiles",MinAge: 0, MaxAge: 35.5 , Type: ChartOwners.CDC, XAxis: ChartAxisNames.Age, YAxis: ChartAxisNames.Length, ResultType:GrowthChartResults.percentile,
    get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {



      let chart =  import("./CDC/Percentiles/cdc_lfa_0_to_3_percentiles")
      .then(file => { return file.default;});
      return  chart;
    } 
  }
  , [ChartListIndexEnum.cdc_sfa_2_to_20_percentiles] : { ChartID: ChartListIndexEnum.cdc_sfa_2_to_20_percentiles, ChartName: "cdc_sfa_2_to_20_percentiles", MinAge: 24, MaxAge: 240,  Type: ChartOwners.CDC,XAxis: ChartAxisNames.Age, YAxis: ChartAxisNames.Length, ResultType: GrowthChartResults.percentile
  , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
    let chart =  import("./CDC/Percentiles/cdc_sfa_2_to_20_percentiles")
    .then(file => file.default);
    return  chart;
    } 
  }
   , [ChartListIndexEnum.cdc_wfa_0_to_3_percentiles]:{ ChartID: ChartListIndexEnum.cdc_wfa_0_to_3_percentiles, ChartName: "cdc_wfa_0_to_3_percentiles",  MinAge: 0, MaxAge: 36, Type: ChartOwners.CDC, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Weight, ResultType: GrowthChartResults.percentile
   , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
    let chart =  import("./CDC/Percentiles/cdc_wfa_0_to_3_percentiles")
    .then(file => file.default);
    return  chart; 
    }
  }
   , [ChartListIndexEnum.cdc_wfa_2_to_20_percentiles]:{ ChartID: ChartListIndexEnum.cdc_wfa_2_to_20_percentiles, ChartName: "cdc_wfa_2_to_20_percentiles", MinAge: 24, MaxAge: 240,  Type: ChartOwners.CDC, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Weight, ResultType: GrowthChartResults.percentile 
   , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
    let chart =  import("./CDC/Percentiles/cdc_wfa_2_to_20_percentiles")
    .then(file => file.default);
    return  chart; 
    }
  }
   ,[ChartListIndexEnum.cdc_hfa_0_to_3_percentiles]:{ ChartID: ChartListIndexEnum.cdc_hfa_0_to_3_percentiles, ChartName: "cdc_hfa_0_to_3_percentiles", MinAge: 0, MaxAge: 36,  Type: ChartOwners.CDC, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Head, ResultType: GrowthChartResults.percentile 
   , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
    let chart =  import("./CDC/Percentiles/cdc_hfa_0_to_3_percentiles")
    .then(file => file.default);
    return  chart; 
    }
  }
   , [ChartListIndexEnum.cdc_wfl_0_to_3_percentiles]:{ ChartID: ChartListIndexEnum.cdc_wfl_0_to_3_percentiles, ChartName: "cdc_wfl_0_to_3_percentiles",  MinAge: 0, MaxAge: 36, Type: ChartOwners.CDC, XAxis: ChartAxisNames.Length, "YAxis": ChartAxisNames.Weight, ResultType: GrowthChartResults.percentile 
   , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
    let chart =  import("./CDC/Percentiles/cdc_wfl_0_to_3_percentiles")
    .then(file => file.default);
    return  chart; 
    }
  }
   ,[ChartListIndexEnum.cdc_Bmifa_2_to_20_percentiles]:{ ChartID: ChartListIndexEnum.cdc_Bmifa_2_to_20_percentiles, ChartName: "cdc_Bmifa_2_to_20_percentiles", MinAge: 24, MaxAge: 240.5, Type: ChartOwners.CDC, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.BMI, ResultType: GrowthChartResults.percentile 
   , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
    let chart =  import("./CDC/Percentiles/cdc_Bmifa_2_to_20_percentiles")
    .then(file => file.default);
    return  chart; 
    }
  } 
  

  , [ChartListIndexEnum.who_hfa_0_to_5_percentiles]:{ ChartID: ChartListIndexEnum.who_hfa_0_to_5_percentiles, ChartName: "who_hfa_0_to_5_percentiles",  MinAge: 0, "MaxAge": 60,  Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Head, ResultType: GrowthChartResults.percentile
  , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
    let chart =  import("./WHO/Percentiles/who_hfa_0_to_5_percentiles")
    .then(file => file.default);
    return  chart; 
    } 
  }
  , [ChartListIndexEnum.who_lfa_0_to_2_percentiles]:{ ChartID: ChartListIndexEnum.who_lfa_0_to_2_percentiles, ChartName: "who_lfa_0_to_2_percentiles",  MinAge: 0, "MaxAge": 24,  Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Length, ResultType: GrowthChartResults.percentile
  , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
    let chart =  import("./WHO/Percentiles/who_lfa_0_to_2_percentiles")
    .then(file => file.default);
    return  chart; 
    } 
   }
  , [ChartListIndexEnum.who_lfa_2_to_5_percentiles]:{ ChartID: ChartListIndexEnum.who_lfa_2_to_5_percentiles, ChartName: "who_lfa_2_to_5_percentiles", MinAge: 24, "MaxAge": 60, Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Length, ResultType: GrowthChartResults.percentile 
  , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
    let chart =  import("./WHO/Percentiles/who_lfa_2_to_5_percentiles")
    .then(file => file.default);
    return  chart; 
    } 
  }
  , [ChartListIndexEnum.who_wfa_0_to_5_percentiles]:{ ChartID: ChartListIndexEnum.who_wfa_0_to_5_percentiles, ChartName: "who_wfa_0_to_5_percentiles",  MinAge: 0, "MaxAge": 60,  Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Weight, ResultType: GrowthChartResults.percentile 
  , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
    let chart =  import("./WHO/Percentiles/who_wfa_0_to_5_percentiles")
    .then(file => file.default);
    return  chart; 
    } 
  }
 , [ChartListIndexEnum.who_wfl_0_to_2_percentiles]:{ ChartID: ChartListIndexEnum.who_wfl_0_to_2_percentiles, ChartName: "who_wfl_0_to_2_percentiles",MinAge: 0, "MaxAge": 24, Type: ChartOwners.WHO, XAxis: ChartAxisNames.Length, "YAxis": ChartAxisNames.Weight, ResultType: GrowthChartResults.percentile 
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/Percentiles/who_wfl_0_to_2_percentiles")
  .then(file => file.default);
  return  chart; 
  } 
}
 , [ChartListIndexEnum.who_wfl_2_to_5_percentiles]:{ ChartID: ChartListIndexEnum.who_wfl_2_to_5_percentiles, ChartName: "who_wfl_2_to_5_percentiles",MinAge: 24, "MaxAge": 60, Type: ChartOwners.WHO, XAxis: ChartAxisNames.Length, "YAxis": ChartAxisNames.Weight, ResultType: GrowthChartResults.percentile 
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/Percentiles/who_wfl_2_to_5_percentiles")
  .then(file => file.default);
  return  chart; 
  } 
}
 , [ChartListIndexEnum.who_Bmifa_0_to_2_percentiles]:{ ChartID: ChartListIndexEnum.who_Bmifa_0_to_2_percentiles, ChartName: "who_Bmifa_0_to_2_percentiles",MinAge: 0, MaxAge: 24, Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.BMI, ResultType: GrowthChartResults.percentile
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/Percentiles/who_Bmifa_0_to_2_percentiles")
  .then(file => file.default);
  return  chart; 
  } 
 }
 , [ChartListIndexEnum.who_Bmifa_2_to_5_percentiles]:{ ChartID: ChartListIndexEnum.who_Bmifa_2_to_5_percentiles, ChartName: "who_Bmifa_2_to_5_percentiles",MinAge: 24, MaxAge: 60, Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.BMI, ResultType: GrowthChartResults.percentile
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/Percentiles/who_Bmifa_2_to_5_percentiles")
  .then(file => file.default);
  return  chart; 
  } 
 }
 , [ChartListIndexEnum.who_Bmifa_5_to_19_percentiles]:{ ChartID: ChartListIndexEnum.who_Bmifa_5_to_19_percentiles, ChartName: "who_Bmifa_5_to_19_percentiles",MinAge: 61, "MaxAge": 228,  Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.BMI, ResultType: GrowthChartResults.percentile 
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/Percentiles/who_Bmifa_5_to_19_percentiles")
  .then(file => file.default);
  return  chart; 
  }
}
 , [ChartListIndexEnum.who_wfa_5_to_10_percentiles]:{ ChartID: ChartListIndexEnum.who_wfa_5_to_10_percentiles, ChartName: "who_wfa_5_to_10_percentiles",MinAge: 61, "MaxAge": 120,  Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Weight, ResultType: GrowthChartResults.percentile
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/Percentiles/who_wfa_5_to_10_percentiles")
  .then(file => file.default);
  return  chart; 
  }
}
 , [ChartListIndexEnum.who_lfa_5_to_19_percentiles]:{ ChartID: ChartListIndexEnum.who_lfa_5_to_19_percentiles, ChartName: "who_lfa_5_to_19_percentiles",MinAge: 61, "MaxAge": 228,  Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Length, ResultType: GrowthChartResults.percentile
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/Percentiles/who_lfa_5_to_19_percentiles")
  .then(file => file.default);
  return  chart; 
  }
}

 , [ChartListIndexEnum.who_hfa_0_to_5_zScore]:{ ChartID: ChartListIndexEnum.who_hfa_0_to_5_zScore, ChartName: "who_hfa_0_to_5_zScore",  MinAge: 0, "MaxAge": 60,              Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Head, ResultType: GrowthChartResults.zscore 
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/zScore/who_hfa_0_to_5_zScore")
  .then(file => file.default);
  return  chart; 
  }
}
 , [ChartListIndexEnum.who_Bmifa_0_to_2_zScore]:{ ChartID: ChartListIndexEnum.who_Bmifa_0_to_2_zScore, ChartName: "who_Bmifa_0_to_2_zScore",  MinAge: 0, "MaxAge": 24,         Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.BMI, ResultType: GrowthChartResults.zscore
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/zScore/who_Bmifa_0_to_2_zScore")
  .then(file => file.default);
  return  chart; 
  }
 }
 , [ChartListIndexEnum.who_Bmifa_2_to_5_zScore]:{ ChartID: ChartListIndexEnum.who_Bmifa_2_to_5_zScore, ChartName: "who_Bmifa_2_to_5_zScore",  MinAge: 24, "MaxAge": 60,        Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.BMI, ResultType: GrowthChartResults.zscore
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/zScore/who_Bmifa_2_to_5_zScore")
  .then(file => file.default);
  return  chart; 
  }
 }
 , [ChartListIndexEnum.who_Bmifa_5_to_19_zScore]:{ ChartID: ChartListIndexEnum.who_Bmifa_5_to_19_zScore, ChartName: "who_Bmifa_5_to_19_zScore",  MinAge: 61, "MaxAge": 228,    Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.BMI, ResultType: GrowthChartResults.zscore 
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/zScore/who_Bmifa_5_to_19_zScore")
  .then(file => file.default);
  return  chart; 
  }
}
 , [ChartListIndexEnum.who_lfa_0_to_2_zScore]:{ ChartID: ChartListIndexEnum.who_lfa_0_to_2_zScore, ChartName: "who_lfa_0_to_2_zScore",  MinAge: 0, "MaxAge": 24,               Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Length, ResultType: GrowthChartResults.zscore
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/zScore/who_lfa_0_to_2_zScore")
  .then(file => file.default);
  return  chart; 
  }
 }
 , [ChartListIndexEnum.who_lfa_2_to_5_zScore]:{ ChartID: ChartListIndexEnum.who_lfa_2_to_5_zScore, ChartName: "who_lfa_2_to_5_zScore",  MinAge: 24, "MaxAge": 60,              Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Length, ResultType: GrowthChartResults.zscore
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/zScore/who_lfa_2_to_5_zScore")
  .then(file => file.default);
  return  chart; 
  }
 }
, [ChartListIndexEnum.who_lfa_5_to_19_zScore]:{ ChartID: ChartListIndexEnum.who_lfa_5_to_19_zScore, ChartName: "who_lfa_5_to_19_zScore",  MinAge: 61, "MaxAge": 228,           Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Length, ResultType: GrowthChartResults.zscore
, get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/zScore/who_lfa_5_to_19_zScore")
  .then(file => file.default);
  return  chart; 
  }
 }
 , [ChartListIndexEnum.who_wfa_0_to_5_zScore]:{ ChartID: ChartListIndexEnum.who_wfa_0_to_5_zScore, ChartName: "who_wfa_0_to_5_zScore",  MinAge: 0, "MaxAge": 60,               Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Weight, ResultType: GrowthChartResults.zscore 
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/zScore/who_wfa_0_to_5_zScore")
  .then(file => file.default);
  return  chart; 
  }
}
 , [ChartListIndexEnum.who_wfa_5_to_10_zScore]:{ ChartID: ChartListIndexEnum.who_wfa_5_to_10_zScore, ChartName: "who_wfa_5_to_10_zScore",  MinAge: 61, "MaxAge": 120,          Type: ChartOwners.WHO, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Weight, ResultType: GrowthChartResults.zscore 
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/zScore/who_wfa_5_to_10_zScore")
  .then(file => file.default);
  return  chart; 
  }
}
 , [ChartListIndexEnum.who_wfl_0_to_2_zScore]:{ ChartID: ChartListIndexEnum.who_wfl_0_to_2_zScore, ChartName: "who_wfl_0_to_2_zScore",  MinAge: 0, "MaxAge": 24,               Type: ChartOwners.WHO, XAxis: ChartAxisNames.Length, "YAxis": ChartAxisNames.Weight, ResultType: GrowthChartResults.zscore
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/zScore/who_wfl_0_to_2_zScore")
  .then(file => file.default);
  return  chart; 
  }
 }
 , [ChartListIndexEnum.who_wfl_2_to_5_zScore]:{ ChartID: ChartListIndexEnum.who_wfl_2_to_5_zScore, ChartName: "who_wfl_2_to_5_zScore",  MinAge: 24, "MaxAge": 60,              Type: ChartOwners.WHO, XAxis: ChartAxisNames.Length, "YAxis": ChartAxisNames.Weight, ResultType: GrowthChartResults.zscore
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./WHO/zScore/who_wfl_2_to_5_zScore")
  .then(file => file.default);
  return  chart; 
  }
 }
 
 , [ChartListIndexEnum.cdc_lfa_0_to_3_zScore]:{ ChartID: ChartListIndexEnum.cdc_lfa_0_to_3_zScore, ChartName: "cdc_lfa_0_to_3_zScore",  MinAge: 0, "MaxAge": 35.5, Type: ChartOwners.CDC, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Length, ResultType: GrowthChartResults.zscore 
 , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
  let chart =  import("./CDC/zScore/cdc_lfa_0_to_3_zScore")
  .then(file => file.default);
  return  chart; 
  }
}
  , [ChartListIndexEnum.cdc_sfa_2_to_20_zScore]:{ ChartID: ChartListIndexEnum.cdc_sfa_2_to_20_zScore, ChartName: "cdc_sfa_2_to_20_zScore",  MinAge: 24, "MaxAge": 240, Type: ChartOwners.CDC, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Length, ResultType: GrowthChartResults.zscore 
  , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
    let chart =  import("./CDC/zScore/cdc_sfa_2_to_20_zScore")
    .then(file => file.default);
    return  chart; 
    }
  }
  , [ChartListIndexEnum.cdc_wfa_0_to_3_zScore]:{ ChartID: ChartListIndexEnum.cdc_wfa_0_to_3_zScore, ChartName: "cdc_wfa_0_to_3_zScore",  MinAge: 0, "MaxAge": 36,  Type: ChartOwners.CDC, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Weight, ResultType: GrowthChartResults.zscore 
  , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
    let chart =  import("./CDC/zScore/cdc_wfa_0_to_3_zScore")
    .then(file => file.default);
    return  chart; 
    }}
  , [ChartListIndexEnum.cdc_wfa_2_to_20_zScore]:{ ChartID: ChartListIndexEnum.cdc_wfa_2_to_20_zScore, ChartName: "cdc_wfa_2_to_20_zScore",  MinAge: 24, "MaxAge": 240, Type: ChartOwners.CDC, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Weight, ResultType: GrowthChartResults.zscore 
  , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
    let chart =  import("./CDC/zScore/cdc_wfa_2_to_20_zScore")
    .then(file => file.default);
    return  chart; 
    }}
  , [ChartListIndexEnum.cdc_hfa_0_to_3_zScore]:{ ChartID: ChartListIndexEnum.cdc_hfa_0_to_3_zScore, ChartName: "cdc_hfa_0_to_3_zScore",  MinAge: 0, "MaxAge": 36,  Type: ChartOwners.CDC, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.Head, ResultType: GrowthChartResults.zscore 
  , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
    let chart =  import("./CDC/zScore/cdc_hfa_0_to_3_zScore")
    .then(file => file.default);
    return  chart; 
    }}
  , [ChartListIndexEnum.cdc_wfl_0_to_3_zScore]:{ ChartID: ChartListIndexEnum.cdc_wfl_0_to_3_zScore, ChartName: "cdc_wfl_0_to_3_zScore",  MinAge: 0, "MaxAge": 36,  Type: ChartOwners.CDC, XAxis: ChartAxisNames.Length, "YAxis": ChartAxisNames.Weight, ResultType: GrowthChartResults.zscore 
  , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
    let chart =  import("./CDC/zScore/cdc_wfl_0_to_3_zScore")
    .then(file => file.default);
    return  chart; 
    }}
  , [ChartListIndexEnum.cdc_Bmifa_2_to_20_zScore]:{ ChartID: ChartListIndexEnum.cdc_Bmifa_2_to_20_zScore, ChartName: "cdc_Bmifa_2_to_20_zScore",  MinAge: 24, MaxAge: 240.5,  Type: ChartOwners.CDC, XAxis: ChartAxisNames.Age, "YAxis": ChartAxisNames.BMI, ResultType: GrowthChartResults.zscore 
  , get chartFile():Promise<IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>> {
    let chart =  import("./CDC/zScore/cdc_Bmifa_2_to_20_zScore")
    .then(file => file.default);

      console.log(JSON.stringify(this.ChartID));
    return  chart; 
    }
  }
}



export class SelectedChart{
   selectedChartIndex?:ChartListIndexEnum;
   get selectChartItem (): ChartItem<ChartListIndexEnum,ChartAxisNames,ChartAxisNames> & IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>
   {
      let chart = import("./CDC/Percentiles/cdc_Bmifa_2_to_20_percentiles");
       return mergeDeep(ChartListLookup[this.selectedChartIndex!],{chartFile : chart});
   }
   constructor(selectedChartIndex?:ChartListIndexEnum)
   {
     this.selectedChartIndex = selectedChartIndex;
   }
 }