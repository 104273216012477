import React, { Component, Fragment } from "react";

import { Link, withRouter } from "react-router-dom";
import {  Navbar, NavItem } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import {RouteComponentProps} from "react-router";

import Routes from "./Routes";
import "./App.css";
import AdSense from "./components/AdSense/AdSense";
import FastVisit from "./containers/FastVisit/FastVisit";
// import { Auth,Cache } from "aws-amplify";

type AppStates = {isAuthenticated:boolean,isAuthenticating:boolean}; 
type AppProps = {history:Array<string>};

type PathParamsType = {
  
}

// Your component own properties
type PropsType = RouteComponentProps<PathParamsType> & AppProps;



class App extends Component<PropsType,AppStates> {
  constructor(props:PropsType) {
    super(props);
  
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }
  
  async componentDidMount() {
    try {
     // await Auth.userSession();
      // await Auth.currentSession();
      //var cre = await Auth.currentAuthenticatedUser();
      //console.log(cre);
      this.userHasAuthenticated(true);
      
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
  
    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = (authenticated:boolean) => {
    this.setState({ isAuthenticated: authenticated });
  }

  // handleLogout = async (event:any) => {
  //   await Auth.signOut();
  //   this.userHasAuthenticated(false);
  //   this.props.history.push("/login");

  // }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };
  
    return (
      !this.state.isAuthenticating &&
      <div className="App container-fluid">
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
          {/* <Navbar.Brand > */}
            {/* <Nav.Link href="/">Home</Nav.Link> */}
            <Navbar.Brand href="/">
              
            <img
              alt=""
              src="/logo.svg"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
            {'Growth Chart Online'}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse data-id="responsive-navbar-nav">
            <Nav className="mr-auto">
               {/* <Nav.Link href="/fastVisit">Fast Visit</Nav.Link> */}
              
               {/* <Fragment>
                <LinkContainer to="/fastVisit">
                  <NavItem>Fast Visit</NavItem>
                </LinkContainer>
              </Fragment> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

         
        {/* <Routes childProps={childProps} /> */}
        <FastVisit></FastVisit>
      </div>
    );
  }

  
}

 export default withRouter(App);
// export default App;
