
import React from "react";
import "./ChartView.css"
import {GrowthChart} from "./GC";
import {VisitItem, Genders, GrowthChartResults} from "../../Common/Common";
import {ChartItem, IChartMetaData, ChartLineTags, MappingFunctionNames,ChartAxisNames, IChartFile,AxesSetupNames,IChartMetaDataByGender, ChartListIndexEnum} from "../../Common/Charts";
import Card from "react-bootstrap/Card";


 interface ChartViewProps{
    gender:Genders
    chartItem?:ChartItem<ChartListIndexEnum,ChartAxisNames,ChartAxisNames>,
    VisitsList: VisitItem[]
 }
 interface ChartViewStates{
    chartFile?:IChartFile<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,GrowthChartResults>,
    chartMetaDataByGender?:IChartMetaDataByGender<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,Genders,GrowthChartResults>,
 };

export class ChartView extends React.Component<ChartViewProps,ChartViewStates>
{
    
    growthchartcontainer:React.RefObject<HTMLDivElement>;
    // growthChartNameSpace:any;
    linesConfiguration:any;

    private growthChartObject?:GrowthChart;
    // new GrowthChart(this.growthchartcontainer.current,this.linesConfiguration,null,null); 

    /*Input: visits, chartType */
    constructor(props:ChartViewProps) {
       
        super(props);
         this.state = {
            chartFile:undefined,
             chartMetaDataByGender:undefined
        };
   
        this.growthchartcontainer = React.createRef();
        // this.growthChartNameSpace = new GC.GC();

        this.linesConfiguration = ['P01', 'P1', 'P3', 'P5', 'P10', 'P15', 'P25', 'P50', 'P75', 'P85', 'P90', 'P95', 'P97', 'P99', 'P999'
        , 'SD5neg', 'SD4neg', 'SD3neg', 'SD2neg', 'SD1P5neg', 'SD1neg', 'SD0P5neg', 'SD0', 'SD0P5', 'SD1', 'SD1P5', 'SD2', 'SD3', 'SD4'];

        
      }

    loadChartMetaData(){
        let that = this;
        if(this.props.chartItem !== undefined 
            && this.props.chartItem.chartFile !== undefined)
        {
           
            this.growthChartObject!.Clear();
            
            this.props.chartItem.chartFile.then(chartFile => {
                 this.setState({chartFile:chartFile},()=>{this.setChartMetaDataByGender();});
            });
            //  this.setState({chartFile:this.props.chartItem.chartFile},()=>{this.setChartMetaDataByGender();});
        }
        else{
           
            this.setState({chartFile:undefined}, () => this.growthChartObject!.Clear());
        }
    }

    setChartMetaDataByGender(){
         if(this.props.chartItem !== undefined 
            && this.props.gender !== 0 
            && this.state.chartFile !== undefined 
            )
            {
                
                let chartMetaDataByGender:IChartMetaDataByGender<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,Genders,GrowthChartResults> = {
                    source:this.state.chartFile.source,
                    meta : this.state.chartFile.meta,
                    gender : Genders.male,
                    data : this.state.chartFile[Genders.male]
                };
                if(this.props.gender == Genders.male)
                {
                    chartMetaDataByGender.gender = Genders.male;
                    // chartMetaDataByGender.meta.Title = this.state.chartFile.meta.Title+"_male";
                    chartMetaDataByGender.data = this.state.chartFile[Genders.male];
                }
                else if(this.props.gender == Genders.female)
                {
                    chartMetaDataByGender.gender = Genders.female;
                    // chartMetaDataByGender.meta.Title = this.state.chartFile.meta.Title+"_female";
                    chartMetaDataByGender.data = this.state.chartFile[Genders.female];
                }
                chartMetaDataByGender.meta.Title = this.state.chartFile.meta.Y_Axis.AXIS_Name +' For ' + this.state.chartFile.meta.X_Axis.AXIS_Name;

                this.setState({chartMetaDataByGender:chartMetaDataByGender}, () => {
                    // let container = React
                    //  this.GC_Draw_Container(this.growthchartcontainer.current);
                    try{
                        this.growthChartObject!.Clear();
                        this.growthChartObject! .Display(this.state.chartMetaDataByGender!);
                        // console.log(JSON.stringify(this.props.VisitsList));
                        this.growthChartObject!.DrawUserData(this.props.VisitsList);
                        this.growthChartObject!.SelectPointByID([-1]);
                    }
                    catch(err){
                        // Do something for an error here
                        console.log("Error Reading data " + err);

                        };
                });

            }
            else{
                console.log("setChartMetaDataByGender --> else");
                this.growthChartObject!.Clear();
            }
    }

    
    componentDidMount()
    {
         this.growthChartObject = new GrowthChart(this.growthchartcontainer.current,this.linesConfiguration,null,null);
         if(this.props.chartItem != undefined)
         {
            this.loadChartMetaData();
         }
    }

    componentDidUpdate(prevProps:ChartViewProps) {
        // console.log("componentDidUpdate");
         if(this.props.chartItem != undefined)
         {
            // if(prevProps.chartItem == undefined ||  prevProps.chartItem!.chartFile !== this.props.chartItem!.chartFile)
            if(prevProps.chartItem == undefined ||  prevProps.chartItem!.ChartID !== this.props.chartItem!.ChartID)
            {
                // console.log("componentDidUpdate --> loadChartMetaData");
                this.loadChartMetaData();
            }
            else  if(
                this.state.chartFile !== undefined
                && prevProps.gender !== this.props.gender)
            {
                // console.log("componentDidUpdate --> setChartMetaDataByGender");
                this.setChartMetaDataByGender();
            }
            else if(this.state.chartFile != undefined 
                && this.props.gender != undefined
                && prevProps.VisitsList != this.props.VisitsList)
                {
                    this.growthChartObject!.DrawUserData(this.props.VisitsList);
                    this.growthChartObject!.SelectPointByID(this.props.VisitsList.map(v => v.visitID));
                }
         }
    }
    render()
    {
       return(
        <Card border="primary">
            <Card.Header className="text-center">Chart</Card.Header>
            <Card.Body className="nopadding">
                <div className="growth_chart_main_container" ref={this.growthchartcontainer}>
                   
                </div>
            </Card.Body>
        </Card>
        )
    }
}

export default ChartView;