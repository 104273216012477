// export  namespace Common
// {
  import moment from "moment";

  export enum Genders{
    male = 1,
    female = -1
  };

  
    export enum Direction
    {
      rtl = "rtl",
      ltr = "ltr"
    }

    

    // export enum WeightUnits {
    //   kg = "kg",
    //   lb = "lb"
    // };

    // export enum BMIUnits{
    //   kg_m2 = "kg/m2",
    //   lb_inch = "lb/inch"
    // }
    export enum Units {
      cm = "cm",
      inch ="in",

      kg = "kg",
      lb = "lb",

      month = "month",
      year = "year",
      day = "day",
      
      kg_m2 = "kg/m2",
      lb_inch = "lb/inch"
    };
    
    export type WeightUnit = Units.lb|Units.kg;

    type WeightConverterIndex = { [k in WeightUnit]: {
        [k in WeightUnit]:number
        }
    } 
    export const WeightUnitConverter: WeightConverterIndex =  
    {
      [Units.kg]: {
      [Units.kg]:1,
        [Units.lb]:2.2046226218
        
      },
      [Units.lb]:{
        [Units.lb]:1,
        [Units.kg]:0.454
      }};


      export type LengthUnit = Units.cm|Units.inch;
      type LengthConverterIndex = { [k in LengthUnit]: {
          [k in LengthUnit]:number
          }
      } 
      export const LengthUnitConverter: LengthConverterIndex =  
      {
        [Units.cm]:{
          [Units.cm]:1,
          [Units.inch]:0.3937007874
        },
        [Units.inch]:{
          [Units.inch]:1,
          [Units.cm]:2.54
        }
      };



      export type PeriodUnit = Units.month|Units.year|Units.day;
      type PeriodConverterIndex = { [k in PeriodUnit]: {
          [k in PeriodUnit]:number
          }
      } 
      export const PeriodUnitConverter: PeriodConverterIndex =  
      {
        [Units.month]:{
          [Units.day]:-1, /*Fake number */
          [Units.month]:1,
          [Units.year]:1/12
        },
        [Units.year]:{
          [Units.day]:-1,
          [Units.year]:1,
          [Units.month]:12
        },
        [Units.day]:{
          [Units.day]:1,
          [Units.month]:-1,
          [Units.year]:-1
        }
      };


      export type BMIUnit = Units.kg_m2|Units.lb_inch;
      type BMIConverterIndex = { [k in BMIUnit]: {
          [k in BMIUnit]:number
          }
      } 
      export const BMIUnitConverter: BMIConverterIndex =  
      {
        [Units.kg_m2]:{
          [Units.kg_m2]:1,
          [Units.lb_inch]:(WeightUnitConverter.kg.lb/(LengthUnitConverter.cm.in*LengthUnitConverter.cm.in))
        },
        [Units.lb_inch]:{
          [Units.lb_inch]:1,
          [Units.kg_m2]:(WeightUnitConverter.lb.kg/(LengthUnitConverter.in.cm*LengthUnitConverter.in.cm))
        }
      };

      type GeneralUnitConverterIndex = { 
        [k in Units]?: {
          [k in Units]?:number
        }
    }; 
 


    // export const GeneralUnitConverter:GeneralUnitConverterIndex = {
    //   [Units.cm]:{[Units.cm]:1, [Units.day]: -1, [Units.inch]: -1, [Units.kg]: -1, [Units]}
    // }

    export const GeneralUnitConverter:GeneralUnitConverterIndex = {...LengthUnitConverter, ...WeightUnitConverter ,...PeriodUnitConverter,...BMIUnitConverter};
      
      //export const GeneralUnitConverter:BMIConverterIndex = {...BMIUnitConverter};


      export enum FastVisitIntents {
        gender = "gender",
        dob = "dbo",
        visitDate="visitDate",
        weight = "Weight",
        weightUnit = "weightUnit",
        length = "Length",
        lengthUnit="lengthUnit",
        head= "Head",
        headUnit = "headUnit",
        bmi="BMI",
        bmiUnit = "bmiUnit",
        selectedChart = "selectedChart"
    }
    


    export enum GrowthChartResults{
      percentile = "%",
      zscore = "z"
    }

export enum ChartOwners {
  CDC = "CDC",
  WHO = "WHO",

}


export interface IVisitItem {
  visitID:number;
  visitDate:Date,
  dob : Date,
  age:number,
  weight:number,
  weightUnit:WeightUnit,
  length:number,
  lengthUnit:LengthUnit,
  head:number,
  headUnit:LengthUnit,
  // bmi:number,
   bmiUnit: BMIUnit,
   bmi:number
  //  resultType: GrowthChartResults
};

export class VisitItem  implements IVisitItem{
  visitID: number = -1;  
  visitDate: Date = new Date();
   
  dob : Date = new Date();
  get age(): number {
                    let a = moment(this.visitDate).startOf(Units.day);
                    let b = moment(this.dob).startOf(Units.day);
                    return a.diff(b,Units.month);
                };

  weight: number = 0;
  weightUnit: WeightUnit = Units.kg;
  length: number = 0;
  lengthUnit: LengthUnit = Units.cm;
  head: number = 0;
  headUnit: LengthUnit = Units.cm;
  bmiUnit: BMIUnit = Units.kg_m2;
  get bmi():number {
    return (this.weight * WeightUnitConverter[this.weightUnit || Units.kg][Units.kg] 
    / (this.length * LengthUnitConverter[this.lengthUnit || Units.cm][Units.cm] * this.length * LengthUnitConverter[this.lengthUnit || Units.cm][Units.cm]) 
    * 10000) * GeneralUnitConverter[Units.kg_m2]![this.bmiUnit]!
  }
  // resultType: GrowthChartResults = GrowthChartResults.percentile;

}

