import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import AgeCalculator from "../UnitConverter/AgeCalculator"
import "./DateOfBirthEdit.css";
import {FastVisitIntents} from "../../Common/Common"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// https://reactdatepicker.com/
 import DatePicker from "react-datepicker";
 import "react-datepicker/dist/react-datepicker.css";
import InputGroup from "react-bootstrap/InputGroup";

import { FaCalendar } from 'react-icons/fa';

//import DatePicker from 'react-date-picker';
// import DatePicker from 'react-date-picker/dist/entry.nostyle';

interface DateOfBirthComponentProps{
    dob?:Date,
    // passedPropertyKey:string,
    // handleChange(propertyKey:string,value:string):void
    updateParentState:(key:FastVisitIntents,value:any) => void
};
interface DateOfBirthComponentStates {
    // dob?:string
};

export default class DateOfBirthComponent extends Component<DateOfBirthComponentProps,DateOfBirthComponentStates>{

    constructor(props:DateOfBirthComponentProps) {
        super(props);
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
        this.handleDateChangeEvent = this.handleDateChangeEvent.bind(this);

      }
      
    handleChangeEvent = (event:any) => {
        this.props.updateParentState(FastVisitIntents.dob,event.target.value);
    }
    // handleDateChangeEvent(date: Date | Date[]) {
    //     // console.log(date.toDateString());
    //     this.props.updateParentState(common.FastVisitIntents.dob,date);
    //   }

    handleDateChangeEvent(date: Date) {
        // console.log(date.toDateString());
        this.props.updateParentState(FastVisitIntents.dob,date);
      }

    render() {
        return (
            <Form.Group controlId="dob" as={Row} size="sm">
                <Col xs={12} sm={12} md={2}>
                    <Form.Label >Date of birth</Form.Label>
                </Col>
                <Col xs={12} sm={12} md={10}> 
                    {/* <Form.Control 
                    type="date"
                    value={this.props.dob!.toString()}
                    onChange={this.handleChangeEvent}
                    placeholder="enter date of birth"
                    size="sm" className="mb-1" /> */}

                    {/* <DatePicker className="mb-1"
                        value={this.props.dob} 
                        onChange={this.handleDateChangeEvent}
                        placeholderText="enter date of birth"
                       /> */}

                <InputGroup className="mb-1" size="sm">
                    
                    <DatePicker className="form-control"
                        
                        dateFormat="yyyy/MM/dd"
                        selected={this.props.dob} 
                        onChange={this.handleDateChangeEvent}
                        showMonthDropdown
                        showYearDropdown
                        maxDate={new Date()}
                       >
                        
                       </DatePicker>
                </InputGroup>
                    <Form.Text className="text-muted">
                        Current Age: <AgeCalculator dob={this.props.dob} visitDate={new Date()}></AgeCalculator>
                    </Form.Text>
                   
                </Col>
            </Form.Group>
        );
    }

}