import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import AgeCalculator from "../UnitConverter/AgeCalculator"
import "./VisitDateEdit.css";
import {FastVisitIntents} from "../../Common/Common";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// https://reactdatepicker.com/
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


interface VisitDateEditProps{
    visitDate?:Date,
    dob?:Date,
    updateParentState:(key:FastVisitIntents,value:any) => void,
}
interface VisitDateEditStates{
   
}
export default class VisitDateEdit extends Component<VisitDateEditProps,VisitDateEditStates>
{

    constructor(props:VisitDateEditProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChangeEvent = this.handleDateChangeEvent.bind(this);

      }
      
    handleChange = (event:any) => {
       this.props.updateParentState(FastVisitIntents.visitDate,event.target.value);
    }
    handleDateChangeEvent(date: Date) {
        // console.log(date.toDateString());
        this.props.updateParentState(FastVisitIntents.visitDate,date);
      }
    render() {
        return (
            <Form.Group controlId="visitDate" as={Row} size="sm">
                <Col xs={2} sm={2}>
                    <Form.Label>Visit Date</Form.Label>
                </Col>

                <Col xs={10} sm={10}>
                    {/* <Form.Control type="date" value={this.props.visitDate} onChange={this.handleChange} size="sm"/> */}
                    <DatePicker className="form-control"
                        
                         dateFormat="yyyy/MM/dd"
                         selected={this.props.visitDate}
                        onChange={this.handleDateChangeEvent}
                        showMonthDropdown
                        showYearDropdown
                        minDate={this.props.dob}
                       />

                    <Form.Text className="text-muted">
                        Age at visit: <AgeCalculator dob={this.props.dob} visitDate={this.props.visitDate}></AgeCalculator>
                    </Form.Text>
                </Col>
            </Form.Group>
        );
    }

};


