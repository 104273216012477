import React, { Component } from "react";
import "./VisitEdit.css";
import VisitDateEdit from "../VisitDateComponent/VisitDateEdit";
import VisitInputComponent from "../VisitInputComponent/VisitInputComponent";
import * as common from "../../Common/Common";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";


interface VisitEditProps{
      updateParentState:(key:common.FastVisitIntents,value:any) => void,
    visit:common.VisitItem,
};

interface VisitEditStates{
    
};

export default class VisitEdit extends Component<VisitEditProps,VisitEditStates>{
    constructor(props:VisitEditProps) {
        super(props);
         
      }
    
    render() {
        return (
            <Card border="primary">
                <Card.Header className="text-center">Visit</Card.Header>
                <Card.Body>
                    
                        <VisitDateEdit dob={this.props.visit.dob} visitDate={this.props.visit.visitDate} updateParentState= {this.props.updateParentState}></VisitDateEdit>
                        <VisitInputComponent unit={this.props.visit.headUnit} unitIntent={common.FastVisitIntents.headUnit} 
                        value={this.props.visit.head} valueIntent={common.FastVisitIntents.head}
                        updateParentState={this.props.updateParentState}></VisitInputComponent>
                   
                        <VisitInputComponent unit={this.props.visit.weightUnit} unitIntent={common.FastVisitIntents.weightUnit} 
                        value={this.props.visit.weight} valueIntent={common.FastVisitIntents.weight}
                        updateParentState={this.props.updateParentState}></VisitInputComponent>
                   
                        <VisitInputComponent unit={this.props.visit.lengthUnit} unitIntent={common.FastVisitIntents.lengthUnit} 
                        value={this.props.visit.length} valueIntent={common.FastVisitIntents.length}
                        updateParentState={this.props.updateParentState}></VisitInputComponent>
                   
                        <VisitInputComponent unit={this.props.visit.bmiUnit} unitIntent={common.FastVisitIntents.bmiUnit} 
                        value={this.props.visit.bmi!} valueIntent={common.FastVisitIntents.bmi}
                        updateParentState={this.props.updateParentState} disabled = {true}></VisitInputComponent>
                 </Card.Body>
           </Card> 
        );
    }

}