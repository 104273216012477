'use strict'
 import * as d3 from "d3";
import * as common from "../../Common/Common";
import {ChartAxisLookup,ChartAxisNames,IChartAxisName,IChartLineTag,ChartLineTags,CharLineTagIndexLookup,MappingFunctionNames,AxesSetupNames,IMappingFunctionOutput, 
    MappingFunctionsLookup,IChartMetaData, AxesSetupsLookup,IChartMetaDataByGender, IChartLineTagItem} from "../../Common/Charts"

import { GrowthChartResults } from "../../Common/Common";
    
 export class PercentileZscoreCalculator
 {
    // private _chart:IChartMetaDataByGender<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,common.Genders,GrowthChartResults>= {
    //     source:[""],
    //     meta:{
    //         lines:[], 
    //         Title:"", 
    //         X_Axis:ChartAxisLookup.Age,
    //         Y_Axis:ChartAxisLookup.Length,
    //         MapFunction:MappingFunctionsLookup[MappingFunctionNames.Bmifa],
    //         AxesSetup:AxesSetupsLookup[AxesSetupNames.cdc_Bmifa_2_20],
    //         OutputResult:GrowthChartResults.percentile
    //      },
    //      gender:common.Genders.male
    //     ,data:undefined
    // };

    public chart?:IChartMetaDataByGender<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,common.Genders,GrowthChartResults> = undefined;
    // private Output_Unit: common.GrowthChartResults = common.GrowthChartResults.percentile;

    public SetChart( chart:IChartMetaDataByGender<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,common.Genders,GrowthChartResults>)
    {
        this.chart = chart;
    }
    public GetPercentile_ZScore_OfNative (nativeRecord:common.VisitItem) 
    {
    //    console.log(JSON.stringify(nativeRecord));
      //  let d = MappingFunctionsLookup[this.chart.meta.MapFunctionID](nativeRecord);
      let d = this.chart!.meta.MapFunction(nativeRecord);
       //if (this.chart.meta.X_AXIS_Name == "Year") {
       //    d[0] = d[0] / 12;
       //}
      //  console.log(JSON.stringify(d));
       return this.GetPercentile(d);
   };

   public GetPercentile (d:IMappingFunctionOutput):string {

    if (this.chart!.meta.lines && CharLineTagIndexLookup[this.chart!.meta.lines[0]][this.chart!.meta.OutputResult]) {
        // If data contains the same [ChartAxisNames.Age]:
        let sameMonthRecord:any = null,
        minGreaterMonthRecord = null, /*the first recordds greater than the selected month point*/
            maxLessMonthRecord = null, /*the first records less than the selected month point*/
            minGreaterMonth = 99999.0,
            maxLessMonth = -99999.0;
        for (let j = 0; j < this.chart!.data!.data.length; j++) {
           
            let itemX = this.chart!.data!.data[j][this.chart!.meta.X_Axis.AXIS_Name]!;
            if (d.X == itemX) {
                sameMonthRecord = this.chart!.data!.data[j];
            }
            else if (parseFloat(minGreaterMonth.toString()) >itemX
                && d.X < itemX) {
                minGreaterMonth = this.chart!.data!.data[j][this.chart!.meta.X_Axis.AXIS_Name]!;
                minGreaterMonthRecord = this.chart!.data!.data[j];
            }

            else if (maxLessMonth < this.chart!.data!.data[j][this.chart!.meta.X_Axis.AXIS_Name]!
                && d.X > this.chart!.data!.data[j][this.chart!.meta.X_Axis.AXIS_Name]!) {
                maxLessMonth = this.chart!.data!.data[j][this.chart!.meta.X_Axis.AXIS_Name]!;
                maxLessMonthRecord = this.chart!.data!.data[j];
            }
        }

        if (!sameMonthRecord != null
            && minGreaterMonthRecord != null
            && maxLessMonthRecord != null) {
            sameMonthRecord = {};

           

            for (let j = 0; j < this.chart!.meta.lines.length; j++) {
                sameMonthRecord[CharLineTagIndexLookup[this.chart!.meta.lines[j]].Tag!] =
                    (
                        (d.X - maxLessMonthRecord[this.chart!.meta.X_Axis.AXIS_Name]!)
                        * (minGreaterMonthRecord[CharLineTagIndexLookup[this.chart!.meta.lines[j]].Tag!]! - maxLessMonthRecord[CharLineTagIndexLookup[this.chart!.meta.lines[j]].Tag!]!)
                        / (minGreaterMonthRecord[this.chart!.meta.X_Axis.AXIS_Name]! - maxLessMonthRecord[this.chart!.meta.X_Axis.AXIS_Name]!)
                    )
                    + maxLessMonthRecord[CharLineTagIndexLookup[this.chart!.meta.lines[j]].Tag!]!;

                   
            }
        }

        if (sameMonthRecord != null) {
            let samePoint = 0.0, minGreaterPoint = 99999.0, maxLessPoint = -99999.0;
            let samePercentil = null, minGreaterPercentil = null, maxLessPercentil = null;
            for (let j = 0; j < this.chart!.meta.lines.length; j++) {
                if (parseFloat(sameMonthRecord[CharLineTagIndexLookup[this.chart!.meta.lines[j]].Tag!]) == d.Y) {
                    
                    
                    
                    samePoint = parseFloat(sameMonthRecord[CharLineTagIndexLookup[this.chart!.meta.lines[j]].Tag!]);
                    samePercentil = CharLineTagIndexLookup[this.chart!.meta.lines[j]][this.chart!.meta.OutputResult]!;

                    break;
                }
                else if (minGreaterPoint > parseFloat(sameMonthRecord[CharLineTagIndexLookup[this.chart!.meta.lines[j]].Tag!])
                    && d.Y < parseFloat(sameMonthRecord[CharLineTagIndexLookup[this.chart!.meta.lines[j]].Tag!])) {
                    minGreaterPoint = parseFloat(sameMonthRecord[CharLineTagIndexLookup[this.chart!.meta.lines[j]].Tag!]);
                    minGreaterPercentil = CharLineTagIndexLookup[this.chart!.meta.lines[j]][this.chart!.meta.OutputResult]!;
                }
                else if (maxLessPoint < parseFloat(sameMonthRecord[CharLineTagIndexLookup[this.chart!.meta.lines[j]].Tag!])
                    && d.Y > parseFloat(sameMonthRecord[CharLineTagIndexLookup[this.chart!.meta.lines[j]].Tag!])) {
                    maxLessPoint = parseFloat(sameMonthRecord[CharLineTagIndexLookup[this.chart!.meta.lines[j]].Tag!]);
                    maxLessPercentil = CharLineTagIndexLookup[this.chart!.meta.lines[j]][this.chart!.meta.OutputResult]!;
                }
            }
            if (samePercentil != null)
            { return samePercentil.toFixed(2); }
            else if (minGreaterPoint < 99999.0
                && minGreaterPercentil != null
                && maxLessPoint > -99999.0
                && maxLessPercentil != null) {
                return (((d.Y - maxLessPoint) * (minGreaterPercentil - maxLessPercentil) / (minGreaterPoint - maxLessPoint)) + maxLessPercentil).toFixed(2);
            }
            else if (minGreaterPoint < 99999.0
                && minGreaterPercentil != null) {
                return "<" + minGreaterPercentil;
            }
            else if (maxLessPoint > -99999.0
                && maxLessPercentil != null) {
                return ">" + maxLessPercentil;
            }
        }


    }
    return "";
    };
}

type ITupleItem <k extends ChartLineTags>= {
    name:string,
    Tag: k,
    x:number,
    y: number
}
export class GrowthChart {
        private selector:any;
        private chartTypeName:any;
        private excludedLines:ChartLineTags[];
        private chart:IChartMetaDataByGender<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,common.Genders,GrowthChartResults>= {
            source:[""],
            meta:{
                lines:[], 
                Title:"", 
                X_Axis:ChartAxisLookup.Age,
                Y_Axis:ChartAxisLookup.Length,
                OutputResult:GrowthChartResults.percentile,
                MapFunction:MappingFunctionsLookup[MappingFunctionNames.Bmifa],
                AxesSetup:AxesSetupsLookup[AxesSetupNames.cdc_Bmifa_2_20],
             },
             gender:common.Genders.male
            ,data:undefined
        };
        private _percentileZscoreCalculator:PercentileZscoreCalculator = new PercentileZscoreCalculator();
        private yMax:number = 0;
        private xMax:number = 0 ;
        private yMin:number = 9999;
        private xMin:number = 9999;
        private yMaxAlternative = 0;
        private xMaxAlternative = 0;
        private yMinAlternative = 9999;
        private xMinAlternative = 9999;

        private lastTuples = Array<ITupleItem<ChartLineTags>>();
        public displayed:boolean = false;
        private lines:any;


        private direction:common.Direction = common.Direction.ltr;
        private selectedDotIndex:number = -1;
        private dotsLength = -1;
        private tooltipText:any = null;
        private currentLocale = [];
        private language_code:any;

        private width = 800;
        private height = 600;
        private padding = 60;
            //this.extraRightPadding = 0; // For line labels ... "severely malnourished" goes offscreen
        private contentWidth = this.width - this.padding - this.padding;
        private contentHeight = this.height - this.padding - this.padding;

        private widthToHightRatio = (this.width) / this.height;

        private xScale = d3.scaleLinear()
                .range([0, this.width - this.padding - this.padding]);
        private xScaleAlternative = d3.scaleLinear()
                .range([0, this.width - this.padding - this.padding]);

        private yScale = d3.scaleLinear()
            .range([this.height - this.padding - this.padding, 0]);

            /*This scale to draw the inch and lb yAxis*/
        private  yScaleAlternative = d3.scaleLinear()
            .range([this.height - this.padding - this.padding, 0]);

            private svg:any;
            private Content:any;
            private AxisX:any;
            private AxisXAlternative:any;
            private AxisY:any;
            private AxisYAlternative:any;
            private AxisXUnit:any;
            private AxisXAlternativeUnit:any;
            private AxisYUnit:any;
            private AxisYAlternativeUnit:any;
            private AxisYRight:any;
            private AxisYText:any;
            private AxisXText:any;
            private titleText:any;
       
            private rectangleToAxis:any;
            private PrivateData:any;

            // private Output_Unit: common.GrowthChartResults = common.GrowthChartResults.percentile;
            private curvesData:any = null;
            private curvesLines:any = null;
            private curvesAreas:any = null;

        constructor (selector:any, lines:any, language_code:any, excludedLines:any) 
        {
            let that = this;
            this.selector = selector;
            this.excludedLines = excludedLines;
            this.lines = lines;
            this.language_code = language_code;

            // this.width = 800;
            // this.height = 600;
            // this.padding = 60;
            //this.extraRightPadding = 0; // For line labels ... "severely malnourished" goes offscreen
            // this.contentWidth = this.width - this.padding - this.padding;
            // this.contentHeight = this.height - this.padding - this.padding;

            // this.widthToHightRatio = (this.width) / this.height;

            /*This is the scale of the content*/
            // this.xScale = d3.scaleLinear()
            //     .range([0, this.width - this.padding - this.padding]);
            // this.xScaleAlternative = d3.scaleLinear()
            //     .range([0, this.width - this.padding - this.padding]);

            // this.yScale = d3.scaleLinear()
            // .range([this.height - this.padding - this.padding, 0]);

            // /*This scale to draw the inch and lb yAxis*/
            // this.yScaleAlternative = d3.scaleLinear()
            // .range([this.height - this.padding - this.padding, 0]);

            // clear exiting growth chart svg .. allows to reset graph with new background
            d3.select(this.selector).select(".growth_chart_main_svg").remove();
            this.svg = d3.select(this.selector).append("svg")
            //.attr("width", width + extraRightPadding)
            //.attr("height", height)
                .attr("xmlns", "http://www.w3.org/2000/svg")
                .attr("version", "1.1")
                .attr("viewBox", "0 0 " + (this.width) + " " + this.height + "")
                .attr("preserveAspectRatio", "xMidYMid meet")
                .attr("class", "growth_chart_main_svg");

            // add a monocolor background
            let backgroundRect = this.svg.append("g");
            backgroundRect.append("rect")
            .attr("width", this.width)
            .attr("height", this.height)
            .attr("class", "backgroundRect")
            .attr("fill", " rgba(255, 255, 255, 1)")
            .attr("stroke", "rgba(20, 20, 20, .8)");

            this.Content = this.svg.append('g').attr('class', 'content').attr("transform", "translate(" + this.padding + "," + (this.padding) + ")");
            this.Content.append("clipPath")
                .attr("id", "clip")
                .append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", this.contentWidth)
                .attr("height", this.contentHeight);
            // The Curves labels are outside of this clip
            /*Stop Zooming*/
            /*this.Content.attr("clip-path", "url(#clip)");*/

            this.svg.append("clipPath")
                .attr("id", "clipAxisX")
                .append("rect")
                .attr("x", 0)
                .attr("y", -this.contentHeight)
                .attr("width", this.contentWidth).attr("height", (this.contentHeight + this.padding));

            this.AxisX = this.svg.append("g")
                .attr("class", "axis x bottom")
                .attr("transform", "translate(" + this.padding + "," + (this.padding + this.contentHeight) + ")");
            /* Stop Zooming
                    .attr("clip-path", "url(#clipAxisX)");
                    */


            this.AxisXAlternative = this.svg.append("g")
                .attr("class", "axis x bottom alternative")
                .attr("transform", "translate(" + this.padding + "," + (this.padding + this.contentHeight + (this.padding * 1 / 3)) + ")");
            /* Stop Zooming
            .attr("clip-path", "url(#clipAxisX)");*/

            this.AxisY = this.svg.append("g")
                .attr("class", "axis y left")
                .attr("transform", "translate(" + this.padding + "," + this.padding + ")");
            this.AxisYAlternative = this.svg.append("g")
                .attr("class", "axis y left alternative")
                .attr("transform", "translate(" + (this.padding * 2 / 3) + "," + this.padding + ")");

            this.AxisXUnit = this.svg.append("g")
                    .attr("transform", "translate(" + (this.padding + this.contentWidth + (1 / 3 * this.padding)) + "," + (this.padding + this.contentHeight + 10) + ")")
                    .append("text")
                    .attr("font-weight", "bold")
                    .attr('class', 'X_Unit');

            this.AxisXAlternativeUnit = this.svg.append("g").attr("transform", "translate(" + (this.padding + this.contentWidth + (1 / 3 * this.padding)) + "," + (this.padding + this.contentHeight + (this.padding * 1 / 3) + 10) + ")").append("text").attr("font-weight", "bold");
            this.AxisYUnit = this.svg.append("g").attr("transform", "translate(" + (this.padding - 20) + "," + (this.padding - 5) + ")").append("text").attr("font-weight", "bold");
            this.AxisYAlternativeUnit = this.svg.append("g").attr("transform", "translate(" + ((this.padding * 2 / 3) - 20) + "," + (this.padding - 5) + ")").append("text").attr("font-weight", "bold");

            this.AxisYRight = this.svg.append("g")
            .attr('class', "axis y right")
            .attr("transform", "translate(" + (this.contentWidth + this.padding) + "," + this.padding + ")");

            this.AxisYText = this.svg.append("text")
                .attr("text-anchor", "middle")
                .attr("transform", "translate(" + (this.padding * 1 / 3) + "," + (this.height - this.padding) / 2 + ")rotate(-90)")
                .attr('class', 'Y_AXIS_DSC');

            this.AxisXText = this.svg.append("text")
                .attr("text-anchor", "middle")
                .attr("transform", "translate(" + (this.width / 2) + "," + (this.height - (this.padding / 10)) + ")")
                .attr('class', 'X_AXIS_DSC');

            this.titleText = this.svg.append("text")
                .attr('class', 'title')
                .attr("x", (this.width / 2))
                .attr("y", 0 + (this.padding / 2))
                .attr("text-anchor", "middle")
                .style("font-size", "20px")
                .style("text-decoration", "underline");

            let tooltipOffset = this.padding + 10;
            let tooltipGroup = this.svg.append("g");

            let tooltipBackground = tooltipGroup.append("rect")
            // .attr("class","tooltip")
            .attr("x", tooltipOffset)
            .attr("y", tooltipOffset)
            .attr("width", 0)
            .attr("height", 0)
            .attr("class", "tooltipTextBackground")


            this.tooltipText = tooltipGroup.append("text");
            this.tooltipText
            .attr("x", tooltipOffset)
            .attr("y", tooltipOffset)
            .attr("class", "tooltipText")
            .style("font-size", "14px");
            // .style("background-color","gray")
            // .text("(Move mouse over a data point to see details)");
            //.text("");

            this.rectangleToAxis = this.Content.append("g");

            // this.Output_Unit = common.GrowthChartResults.percentile;
            this.curvesData = null;
            this.curvesLines = null;
            this.curvesAreas = null;


            /*Stop Zooming
            this.zoom = null;
            this.zoomAlternative = null;
            */

            this.PrivateData = {
                yAxis : d3.axisLeft(this.yScale).scale(this.yScale).ticks(10)/*.tickSize(-this.contentWidth)*/.tickFormat(()=>'')
                ,yAxisAlternative: d3.axisLeft(this.yScaleAlternative).scale(this.yScaleAlternative).ticks(10).tickFormat(() => '').tickSize(0)//.tickSize(-this.contentWidth)

                , xAxis: d3.axisBottom(this.xScale).scale(this.xScale)/*.tickSize(-this.contentHeight)*/.tickFormat(() => '')/*.outerTickSize(6).innerTickSize(10)*/
                , xAxisAlternative: d3.axisBottom(this.xScaleAlternative).scale(this.xScaleAlternative).tickFormat(() => '').tickSize(0)/*.outerTickSize(6).innerTickSize(10)*/

                , yAxisRight: d3.axisRight(this.yScale).tickSizeOuter(0)/*Set OuterTickSize to hide the axis limit*/

                , yAxisRightScale: d3.scaleOrdinal()
                , lineInterpolate: d3.line().curve(d3.curveBasis)
                    .x(function (d:any, i:any) { 
                        //console.log("lineInterpolate - X" + JSON.stringify(d));
                        return that.xScale(d[0]); })
                    .y(function (d) { 
                        //console.log("lineInterpolate - Y" + JSON.stringify(d));
                        return that.yScale(d[1]); 
                    })
            }
            //this.Content.call(zoom);

            /*Stop Zooming*/
            //this.zoom = d3.behavior.zoom().scaleExtent([1, 12]).on("zoom", function () { that.Zoomed(that) });
            //this.zoomAlternative = d3.behavior.zoom().scaleExtent([1, 12]);
            ///*Set this Rect To be related to the SVG (not the Content), so that it will not be scaled.*/
            //this.svg.append("rect")
            //.attr("class", "pane")
            //.attr('fill', 'none')
            //.attr('cursor', 'move')
            //.attr('pointer-events', 'all')
            //.attr("width", this.contentWidth)
            //.attr("height", this.contentHeight)
            //.attr("transform", "translate(" + this.padding + "," + this.padding + ")")
            ////.attr("transform", "translate(" + this.padding + "," + (this.padding) + ")")
            //.call(this.zoom);
            this.ApplyStyle();
        }
        
        
        public DrawUserData(userData:common.VisitItem[]) {
            let that =  this;
            // let d = MappingFunctions[this.chart.meta.MapFunctionID](nativeRecord);
            let mappingFunction =  this.chart.meta.MapFunction;
            // let requiredUserData:Array<MappingFunctionOutput> = Array.prototype.map.call(userData,mappingFunction)
            //     .filter(function (item:any) {
            //         if (item.X && item.Y) return true;
            //         return false;
            //     }).map((item:any):MappingFunctionOutput => {
            //         return {
            //             X : item.X,
            //             Y : item.Y,
            //             DataID : item.DataID,
            //             ResultType : item.Result,
            //         }
            //     });

            let requiredUserDataNormalized:Array<IMappingFunctionOutput> = userData.map(mappingFunction)
                .filter(item => {
                    if(item.X >= 0 
                        && item.Y >=0 
                        && item.X >= that.xMin && item.X <= that.xMax
                        && item.Y >= that.yMin && item.Y <= that.yMax)
                        return true;
                    return false;
                })
                .sort((a,b) => a.X - b.X);

            // let requiredUserDataNormalized = [];
            // for (let idx = 0, item:MappingFunctionOutput[] = []; idx < requiredUserData.length; idx++) {
            //     if (requiredUserData[idx].Y < this.yMin || requiredUserData[idx].Y > this.yMax)
            //         continue;
            //     item = [[, , ,]] /*x,y,idx,result*/
            //     /*Data Age will be passed in Month. In case the X_AXIS_NAME == "Year", Convert the passed data to Years instead of Months. */
            //     //if (this.chart.meta.X_AXIS_Name == "Year") {
            //     //    if (requiredUserData[idx][0] / 12 >= this.xMin && requiredUserData[idx][0] / 12 <= this.xMax) {
            //     //        //patientDataNormalized.push([patient[idx][0] / 12, patient[idx][1]]);
            //     //        item[0][0] = requiredUserData[idx][0] / 12;
            //     //    }
            //     //    else continue;

            //     //}
            //     //else {
            //     if (requiredUserData[idx].X >= this.xMin && requiredUserData[idx].X <= this.xMax) {
            //         //patientDataNormalized.push(patient[idx]);
            //         item[0][0] = requiredUserData[idx].X;
            //     }
            //     else continue;
            //     //}



            //     item[0][1] = requiredUserData[idx].Y;
            //     item[0][2] = requiredUserData[idx].DataID;
            //     item[0][3] = requiredUserData[idx].ResultType;/*Result*/
            //     requiredUserDataNormalized.push(item[0]);
            // }
            // requiredUserDataNormalized.sort(function (a:any, b:any) { return a[0] - b[0] });
            this.dotsLength = requiredUserDataNormalized.length;
            //let xScale = this.xScale;/*refer to the this.xScale to use it inside x function*/
            //let yScale = this.yScale;

            // Line generating function
            //let line = d3.svg.line()
            //  .interpolate("basis")
            //  .x(function (d:any, i:any) {
            //      return that.xScale(d[0]);
            //  })
            //  .y(function (d) {
            //      return that.yScale(d[1]);
            //  });

            // Add line for the patient's growth
            //this.svg.selectAll(".pLine").remove();

            //this.svg.selectAll("pG")
            //  .data([requiredUserDataNormalized])
            //  //.attr("class", "pG")
            //  .enter().append("path")
            //  .attr("class", "pLine")
            //  .attr("d", line.interpolate("")); // interpolate("") removes the smoothing


            this.Content.selectAll("pG").attr("class", "pG");
            let pathes = this.Content.selectAll('.pLine')
                .data([requiredUserDataNormalized])
              ////.attr("class", "pG")
              //.enter().append("path")
              .attr("class", "pLine")
              .attr("d", that.PrivateData.lineInterpolate/*.interpolate("")line.interpolate("")*/); // interpolate("") removes the smoothing
            pathes.enter()
                .append("path")
                .attr("class", "pLine")
                .attr("d", that.PrivateData.lineInterpolate/*.interpolate("")line.interpolate("")*/);

            pathes.exit().remove();

            //let totalLength = pathes.node().getTotalLength();
            pathes.attr("stroke-dasharray", function (d:any,index:any,group:any) {
                // console.log("");
                return group[index].getTotalLength() + " " + group[index].getTotalLength();
            })
            .attr("stroke-dashoffset", function (d:any,index:any,group:any) {
                return group[index].getTotalLength();
            })
            .transition()
            .duration(1000)
            .ease(d3.easeLinear)
            .attr("stroke-dashoffset", 0);

            // Dots at each data point
            /*
            this.svg.selectAll(".dot").remove();
            this.svg.selectAll(".dotSelected").remove();

            //dots.remove();

            this.svg.selectAll(".dot").data(requiredUserDataNormalized)
              .enter()
              .append("circle")
                .attr('class', 'dot')
              //.attr("class", function (d:any, i:any) {
              //    return "dot "+"dot"+i;
              //})
                .attr('id', function (d:any, i:any) {
                    return d[2];
                })
            //.call(dotHandler(function (d:any, i:any) {
            //    return getTooltipText(d);
            //}))
            // .on("mouseout", mouseoutDot)
            .attr("cx", function (d:any, i:any) { return that.xScale(d[0]); })
            .attr("cy", function (d:any, i:any) { return that.yScale(d[1]); })
            .attr("r", 3);
            */
            let circles = this.Content.selectAll("circle").data(requiredUserDataNormalized);
                /*Force position adjustment so that, in case the chart was changed, the circle position will be changed.*/
            //    .attr('class', 'dot')
            //    .attr('id', function (d:any, i:any) { return d[2]; })
            //     .attr("cx", function (d:any, i:any) { return that.xScale(d[0]); })
            //    .attr("cy", function (d:any, i:any) { return that.yScale(d[1]); })
            //    .attr("r", 3);
            circles.exit().remove();
            circles.enter().append('circle').attr('class', 'dot')
                .attr('id', function (d:IMappingFunctionOutput, i:any) { return d.DataID; })
                 .attr("cx", function (d:IMappingFunctionOutput, i:any) { return that.xScale(d.X); })
                .attr("cy", function (d:IMappingFunctionOutput, i:any) { return that.yScale(d.Y); })
                .attr("r", 4);
                // .on("mouseover", function() { console.log("mouseover"); /* do stuff */ })
                // .on("mouseout", function() { console.log("mouseout"); /* do stuff */ })
                // .on("click", function(d:MappingFunctionOutput) { 
                //     console.log("click");
                //     that.SelectPointByID([d.DataID],that);
                // });

                // .on({
                //     "mouseover": function() { console.log("mouseover"); /* do stuff */ },
                //     "mouseout":  function() { console.log("mouseout"); /* do stuff */ }, 
                //     "click":  function() { console.log("click"); /* do stuff */ }
                //   });
           

           
        };

        private GetCurvesData() {
            /*this function get lines data from chartData.Data then pivot it (seperate each line to be in its own array */
            /*input:  { "Month": "0", "P1": "31.5", "P3": "32.1", "P5": "32.4", "P15": "33.1", "P25": "33.6", "P50": "34.5", "P75": "35.3", "P85": "35.8", "P95": "36.6", "P97": "36.9", "P99": "37.4" }
                    , { "Month": "1", "P1": "34.6", "P3": "35.1", "P5": "35.4", "P15": "36.1", "P25": "36.5", "P50": "37.3", "P75": "38.1", "P85": "38.5", "P95": "39.2", "P97": "39.5", "P99": "40" }
                    ,......
                    , */
            /*output:
                [
                    [[0,31.5], [1,34.6],...]    //P1
                    [[0,32.1], [1,35.1],...]    //P3
                    [[0,32.4], [1,35.4],...]    //P5
                    [[0,33.1], [1,36.1],...]    //P15
                    [[0,33.6], [1,36.5],...]    //P25
                ]
                .....
            */

            this.yMax = -9999;
            this.xMax = -9999;
            this.yMin = 9999;
            this.xMin = 9999;

            this.yMaxAlternative = -9999;
            this.xMaxAlternative = -9999;
            this.yMinAlternative = 9999;
            this.xMinAlternative = 9999;

            //this.lastTuples = [];
            let data = this.chart.data!.data;
            let newLines:any[][] = [];
           
            for (let i = 0; i < this.chart.meta.lines.length; i++) {
                // Get the tag
                let lineTag = CharLineTagIndexLookup[this.chart.meta.lines[i]].Tag;
                newLines.push([]);

                for (let j = 0; j < data.length; j++) {

                    let x = Number( data[j][this.chart.meta.X_Axis.AXIS_Name]);
                    let y = Number( data[j][lineTag!]);
                    newLines[i].push([x , y]);

                    this.xMax = Math.max(x, this.xMax);
                    this.xMin = Math.min(x, this.xMin);

                    this.yMax = Math.max(y, this.yMax);
                    this.yMin = Math.min(y, this.yMin);
                }
                //this.lastTuples.push(newLines[i][newLines[i].length - 1]);
            }
            //yMin -= 3;
            this.yMin -= Math.abs(((this.yMax - this.yMin) * 0.05));
            this.yMax += Math.abs(((this.yMax - this.yMin) * 0.1));

            this.curvesData = newLines;
        };
        private PrepareCurvesLabel() {
            let that = this;
            that.PrivateData.yAxisRightScale = d3.scaleOrdinal();//.domain(chart.lines);
            //that.PrivateData.yAxisRight
        };
        private DrawCurvesLabel () {
            let that = this;
            //this.svg.selectAll("text.line-label").remove();
            //// Line labels (Normal, Malnourished, and Severely Malnourished)
            //let that = this;
            //for (let i = 0; i < this.chart.meta.lines.length; i++) {
            //    xOffset = this.xScale(this.lastTuples[i][0]);
            //    xOffset += 2; // a little space better graph and text
            //    yOffset = this.yScale(this.lastTuples[i][1]);
            //    yOffset += 4; // center text on line

            //    this.svg.append("text")
            //      .attr("class", function () {
            //          return "line-label " + that.chart.meta.lines[i].tag;
            //      })
            //      .attr("transform", "translate(" + (this.padding + xOffset) + "," + (this.padding + yOffset) + ")")
            //      // .attr("text-anchor", "middle")
            //      .text(this.chart.meta.lines[i].name);
            //}

            // Adding 2 to X to add space , and 4 to Y to to center text to line
            //let curveLabels = this.svg.selectAll("text.line-label").data(this.lastTuples);
            //curveLabels.attr('class', function (d:any, i:any) { return "line-label " + that.chart.meta.lines[i].tag; })
            //    .attr('transform', function (d:any, i:any) { return "translate(" + (2 + that.padding + that.xScale(that.lastTuples[i][0])) + "," + (4 + that.padding + that.yScale(that.lastTuples[i][1])) + ")"; })
            //    .text(function (d:any, i:any) { return that.chart.meta.lines[i].name });

            //curveLabels.enter().append('text').attr('class', function (d:any, i:any) { return "line-label " + that.chart.meta.lines[i].tag; })
            //    .attr('transform', function (d:any, i:any) { return "translate(" + (2 + that.padding + that.xScale(that.lastTuples[i][0])) + "," + (4 + that.padding + that.yScale(that.lastTuples[i][1])) + ")"; })
            //    .text(function (d:any, i:any) { return that.chart.meta.lines[i].name });
            //curveLabels.exit().remove();



            this.lastTuples = [];
            d3.selectAll('path.line').each(function () {
                /*Path_BS: this is the current node.
                            that is the chart object.
                */
                that.lastTuples.push(that.Path_GetPointAtPosition(this, that.contentWidth));
            });

            //that.PrivateData.yAxisRightScale = d3.scale.ordinal();//.domain(chart.lines);
            //that.PrivateData.yAxisRight

            that.PrivateData.yAxisRightScale
                /*Note the domain must be array of arrayes. */
                .domain(that.lastTuples.map(function (d:ITupleItem<ChartLineTags>, index:any) { return [d.name, d.Tag, index];/* { label: d.label, tag: d.tag }; */ }))
                .range(that.lastTuples.map(function (d:ITupleItem<ChartLineTags>) { return d.y; }));

            that.PrivateData.yAxisRight.scale(that.PrivateData.yAxisRightScale);//.tickSize(5);//.tickValues(that.lastTuples);

            let ticks = that.AxisYRight
                .call(that.PrivateData.yAxisRight)
                .selectAll('.tick').attr('class', function (CurveLabelObject:any) { 
                    // console.log(JSON.stringify(CurveLabelObject));
                    return "curve tick " + ChartLineTags[parseInt(CurveLabelObject[1])]; 
                });
            //.selectAll('line').attr('class', function (CurveLabelObject) { return "tick " + CurveLabelObject[1]; })

            ticks.selectAll('text').attr('class', function (CurveLabelObject:any) { return "curve label " + ChartLineTags[parseInt(CurveLabelObject[1])] /*CurveLabelObject[1]*//*that.lastTuples[i].tag*/ })
                        .text(function (CurveLabelObject:any) { return CurveLabelObject[0]; /*that.lastTuples[i].label*/ })
                        .attr('x', function (CurveLabelObject:any) { return 9 + ((CurveLabelObject[2] % 2) * (that.padding * 1 / 3)) });

            ticks.selectAll('line').attr('class', function (CurveLabelObject:any) { return 'curve '+ ChartLineTags[parseInt(CurveLabelObject[1])]/*CurveLabelObject[1]*/; })
                .attr('x2', function (CurveLabelObject:any) { return 6 + ((CurveLabelObject[2] % 2) * (that.padding * 1 / 3)) });
            //.selectAll('text')
            //    .data(function (d) { return [d]; })
            //    .enter().append('tspan').text(function (d:any, i:any) {  });
            //.data(function (d:any, i:any) { return that.lastTuples[i]; })
            //.attr('class', function (d:any, i:any) {  })
            //.selectAll('text')
            ////.selectAll('tspan')
            //.data(function (d) { return [d]; })
            //.enter()
            //.append('tspan')
            //;//.text(function (d) {   return d; })
            //.append('tspan').text(function (d) { return d.label; });
            //.selectAll('text')
            //.selectAll('tspan')
            //.data(function (d:any, i:any) { return [{ label: d.label, tag: d.tag }] })
            //.enter()
            //.append('tspan')
            //.attr('class', function (d:any, i:any) { return "label " + d.tag; })
            //.text(function (d) { return d });
            //let yAxisRight = d3.svg.axis();
            /*no need for the scale as the lastTuples will contains the scaled values.*/
            // yAxis.scale(that.yScale);
            //yAxisRight.orient("right")
            //that.PrivateData.yAxis.tickValues(this.lastTuples.map(function (d) { return d.y; }))
            ////    .ticks(this.lastTuples, function (d) { return d.y;})
            //.tickFormat('');

            //.tickValues([283,266,257,250]);

            ////this.AxisYRight.selectAll("*").remove();
            //this.AxisYRight.call(yAxisRight)
            // .selectAll('text')
            //    .selectAll('tspan')
            //    .data(function (d:any, i:any) {
            //        let fmt = d3.format('3f');
            //        //if (d == 0)
            //        //    return [0, 'Birth']
            //        //if (d % 12 == 0)
            //        //    return [fmt(d % 12), fmt(d / 12) + ' Year'];
            //        //return [fmt(d % 12), ''];

            //        return [{ label: that.lastTuples[i].label, tag: that.lastTuples[i].tag }];
            //    })
            //    .enter()
            //    .append('tspan')
            //    .attr('class', function (d:any, i:any) {
            //        return "label " + d.tag;//that.lastTuples[i].tag;
            //    })
            //    //.attr('x', 0)
            //    //.attr('dx',function (d:any, i:any) { return (-2 * i) + 'em'; }) 
            //    //.attr('dy', '1em')// function (d:any, i:any) { return (-2 * i) + 'em'; })

            //    .text(function (d) { return d.label });
            ////.selectAll('text').data(function (d:any, i:any) { return that.chart.meta.lines[i].tag }).enter().append('text').text(String);

        };
        private Path_GetPointAtPosition= function (node:any, xPos:any):ITupleItem<ChartLineTags>{
            let pathLength = node.getTotalLength();
            let x = xPos; 
            let beginning = x, 
            end = pathLength, 
            target;
            let pos:any;
            

            while (true) {
                target = Math.floor((beginning + end) / 2);
                pos = node.getPointAtLength(target);
                if ((target === end || target === beginning) && pos.x !== x)
                { break; }
                if (pos.x > x)
                    end = target;
                else if (pos.x < x) beginning = target; 
                else break;
            }
            //  pos.label = d3.select(node).attr('data-label');
            //  pos.tag = d3.select(node).attr('data-tag');
            //  return pos
            let t:ChartLineTags =  parseInt(d3.select(node).attr('data-tag'));
        // let t:ChartLineTags =  0;
        
            let point:ITupleItem<ChartLineTags> = {
                 Tag:parseInt(d3.select(node).attr('data-tag')),
                name :d3.select(node).attr('data-label'),
                x:pos.x,
                y:pos.y
            }
            //console.log(JSON.stringify(point));
             return point;
        };
        private DrawCurves () {
            let that = this;
            // Line generating function
            this.curvesLines = d3.line()
              //.interpolate("basis")
             
              .x(function (d:any, i:any) {
                //   console.log("x");
                  let rslt = that.xScale(d[0]);
                //   console.log("d = "+ d + ", rslt  = " + rslt);
                  return rslt;
              })
              .y(function (d) {
                //   console.log("y");
                  let rslt = that.yScale(d[1]);
                //   console.log("d = "+ d + ", rslt  = " + rslt);
                  return rslt;
              })
              .curve(d3.curveBasis);

            // Area under the curve, for highlighting regions
            this.curvesAreas = d3.area()
              //.interpolate("basis")
              .curve(d3.curveBasis)
              .x(this.curvesLines.x())
              .y1(this.curvesLines.y())
              .y0(that.yScale(that.yMin));

            // Baseline growth curves
            //this.svg.selectAll("path.line").remove();
            //this.svg.selectAll("path.areamale").remove();
            //this.svg.selectAll("path.areafemale").remove();

            //let lines = this.svg.selectAll(".lines")
            //  .data(curvesData)
            //  .enter();
            //.attr("class","lines");
            /*
            this.svg.selectAll("path.area").remove();
            this.svg.selectAll("path.line").remove();
            this.svg.selectAll("path.area")
                .data(curvesData)
                .enter()
                .append("path")
                .attr("class", function () {
                    if (that.chart.meta.gender == 'M')
                        return "area areamale";
                    return "area areafemale";
                })
                .attr("d", area);

            this.svg.selectAll("path.line")
               .data(curvesData)
               .enter()
                .append("path")
                .attr("class", function (d:any, i:any) {
                    return "line " + that.chart.meta.lines[i].tag;
                })
              .attr("d", line);
              */

            /*
                this.Content.selectAll("path.area").attr("d", this.curvesAreas);
                this.Content.selectAll('path.line').attr("d", this.curvesLines);
            */
           //console.log(JSON.stringify(this.curvesData));
            let pathAreas = this.Content.selectAll("path.area").data(this.curvesData);//.attr('class', function () { if (that.chart.meta.gender == 'M') return "area areamale"; return "area areafemale"; }).attr("d", area);
            pathAreas.exit().remove();
            pathAreas.enter().append('path')//.attr('class', function () { if (that.chart.meta.gender == 'M') return "area areamale"; return "area areafemale"; }).attr("d", area);
            .attr("d", this.curvesAreas)

            .attr('class', function (d:any, i:any) {
                // console.log("class");
                if (that.chart.gender == common.Genders.male)
                    return "curve area areamale " + ChartLineTags[CharLineTagIndexLookup[that.chart.meta.lines[i]].Tag].toString();
                return "curve area areafemale " + ChartLineTags[CharLineTagIndexLookup[that.chart.meta.lines[i]].Tag].toString();
            })

            .style('opacity', '0')
            .transition()
            .duration(1000)
            .ease(d3.easeLinear)
            .style('opacity', '0.2');

            // .attr("d", this.curvesAreas);
           
            
            // pathAreas
           
            /*.attr("clip-path", "url(#clip)");*/

                // console.log(JSON.stringify(that.curvesData[0]));
            let pathLines = this.Content.selectAll('path.line').data(that.curvesData);//.attr("class", function (d:any, i:any) { return "line " + that.chart.meta.lines[i].tag; }).attr("d", line);
            pathLines.enter().append('path')//.attr("class", function (d:any, i:any) { return "line " + that.chart.meta.lines[i].tag; }).attr("d", line);
            .attr("d", this.curvesLines)
            .attr("class", function (d:any, i:any) { return "curve line " + ChartLineTags[CharLineTagIndexLookup[that.chart.meta.lines[i]].Tag].toString(); })
                // .attr("d", this.curvesLines)
                .attr('data-label', function (d:any, i:any) { return CharLineTagIndexLookup[that.chart.meta.lines[i]].name; })
                .attr('data-tag', function (d:any, i:any) { return CharLineTagIndexLookup[that.chart.meta.lines[i]].Tag })

                .attr("stroke-dasharray", function (d:any,index:any,group:any) { return group[index].getTotalLength() + " " + group[index].getTotalLength(); })
                .attr("stroke-dashoffset", function (d:any,index:any,group:any) { return group[index].getTotalLength(); })
                .transition()
                .duration(1000)
                //.ease("linear")
                .ease(d3.easeLinear)
                .attr("stroke-dashoffset", 0);

            /*.attr("clip-path", "url(#clip)");*/
            pathLines.exit().remove();

            // To Animate the drawing of the line.
            // let totalLength = pathLines.node().getTotalLength();
           
        };

        private DrawHeader () {
            let that = this;
            //that.svg.select("text.title").remove();

            //that.svg.append("text")
            //    .attr('class', 'title')
            //    .attr("x", (that.width / 2))
            //    .attr("y", 0 + (that.padding / 2))
            //    .attr("text-anchor", "middle")
            //    .style("font-size", "20px")
            //    .style("text-decoration", "underline")
            this.titleText.style("direction", that.direction)
                .text(that.GetLocalizedString(that.chart.meta.Title));
        };

        private PrepareAxes () {
            let that = this;

            that.AxisX.selectAll("*").remove();
            that.AxisXAlternative.selectAll("*").remove();

            that.AxisY.selectAll("*").remove();
            that.AxisYAlternative.selectAll("*").remove();

            //that.AxisYRight.selectAll("*").remove();

            //if (that.chart.meta.X_AXIS_Name == "Month") {
            //    this.PrivateData.xAxis.ticks((that.xMax - that.xMin + 1) / 2).tickFormat('');
            //}
            //else if (that.chart.meta.X_AXIS_Name == "Year") {
            //    this.PrivateData.xAxis.ticks((that.xMax - that.xMin + 1));
            //}
            //else {
            //    this.PrivateData.xAxis.ticks(20).tickFormat(null);
            //}

            let step = 1 / that.chart.meta.AxesSetup!.xAxis.TickFactor; //GC.Axes[that.chartTypeName]['yAxis'].TickFactor;
            let min = that.xScale.domain()[0];
            min -= (min % step);//- step;
            let max = that.xScale.domain()[1];
            max += -(max % step) + step;
            this.PrivateData.xAxis.tickValues(d3.range(min, max, step));

            if (that.chart.meta.AxesSetup!.xAxisAlternative) { //GC.Axes[that.chartTypeName]["yAxisAlternative"]) {
                //let ticks = (Math.ceil(that.yMax) - Math.floor(that.yMin)) * GC.Axes[that.chartTypeName]["yAxisAlternative"].TickFactor;
                step = 1 / that.chart.meta.AxesSetup!.xAxisAlternative.TickFactor; //GC.Axes[that.chartTypeName]["yAxisAlternative"].TickFactor;
                min = that.xScaleAlternative.domain()[0];
                min -= (min % step);//- step;
                max = that.xScaleAlternative.domain()[1];
                max += -(max % step) + step;
                this.PrivateData.xAxisAlternative.tickValues(d3.range(min, max, step));
            }

            step = 1 / that.chart.meta.AxesSetup!.yAxis.TickFactor; //GC.Axes[that.chartTypeName]['yAxis'].TickFactor;
            min = that.yScale.domain()[0];
            min -= (min % step) - step;
            max = that.yScale.domain()[1];
            max += (max % step) - step;
            this.PrivateData.yAxis.tickValues(d3.range(min, max, step));

            //this.PrivateData.yAxis.ticks((that.yMax - that.yMin) * GC.Axes[that.chartTypeName]['yAxis'].TickFactor);
            //this.PrivateData.yAxis.tickValues(
            //      _.range(
            //                Math.ceil(that.yScale.domain()[0])
            //                , Math.floor(that.yScale.domain()[1] + 1)
            //                , 1 / GC.Axes[that.chartTypeName]['yAxis'].TickFactor)
            //    );
            if (that.chart.meta.AxesSetup!.yAxisAlternative) { //GC.Axes[that.chartTypeName]["yAxisAlternative"]) {
                //let ticks = (Math.ceil(that.yMax) - Math.floor(that.yMin)) * GC.Axes[that.chartTypeName]["yAxisAlternative"].TickFactor;

                step = 1 / that.chart.meta.AxesSetup!.yAxisAlternative.TickFactor; //GC.Axes[that.chartTypeName]["yAxisAlternative"].TickFactor;
                min = that.yScaleAlternative.domain()[0];
                min -= (min % step) - step;
                max = that.yScaleAlternative.domain()[1];
                max += (max % step) - step;

                this.PrivateData.yAxisAlternative.tickValues(d3.range(min, max, step));

                //Math.floor(that.yScaleAlternative.domain()[0])
                //- (that.yScaleAlternative.domain()[0] % (1 / GC.Axes[that.chartTypeName]["yAxisAlternative"].TickFactor))
                //, Math.ceil( + 1)


                //this.PrivateData.yAxisAlternative.ticks((that.yMax - that.yMin) * GC.Axes[that.chartTypeName]["yAxisAlternative"].TickFactor);
                //let ticks = (Math.ceil(that.yMax) - Math.floor(that.yMin)) * GC.Axes[that.chartTypeName]["yAxisAlternative"].TickFactor;

                //ticks -= ticks % GC.Axes[that.chartTypeName]["yAxisAlternative"].TickFactor;
                //ticks = Math.floor(ticks);
                //this.PrivateData.yAxisAlternative.ticks(ticks);

            }
        };
        private DrawAxisY() {
             let that = this;
             // y-axis


             //that.svg.append("g")
             //  .attr("class", "axis")
             //  .attr("transform", "translate(" + that.padding + ",0)")
             //that.AxisY.selectAll("*").remove();
             /*Draw Acess Y*/
             let tempAxisY = that.AxisY.call(that.PrivateData.yAxis);
             tempAxisY.selectAll('g').selectAll('line')
                  .data(function (d:any, i:any) { return [d % that.chart.meta.AxesSetup!.yAxis.PrimaryEvery]; })
                 .attr('class', function (d:any, i:any) {
                     if (Math.abs(0 - d) <= 0.001)
                         return 'main';
                     return 'secondary';
                 })
             .attr("x2", function (d:any, i:any) {
                 if (Math.abs(0 - d) <= 0.001)
                     return that.contentWidth;
                 return 0;
             });

             tempAxisY.selectAll('g').selectAll('line.innerTick')
                 .data(function (d:any, i:any) { return [d % that.chart.meta.AxesSetup!.yAxis.PrimaryEvery];  /*GC.Axes[that.chartTypeName]['yAxis'].PrimaryEvery]*/ })
                 .enter().append('line')
                 .attr('class', 'innerTick')
                 .attr('x2', function (d:any, i:any) {
                     //if (d - Math.floor(d) == 0 && d % 5 == 0)return -3;

                     if (Math.abs(0 - d) <= 0.001) return -3;
                     else if (that.chart.meta.AxesSetup!.yAxis.SecondaryEvery[d]) //GC.Axes[that.chartTypeName]['yAxis'].SecondaryEvery[d])
                         return -that.padding / 3;
                     else return -3;
                 })
                 .attr('y2', 0)
                 .style('stroke', function (d:any, i:any) {
                     //if (d - Math.floor(d) == 0 && d % 5 == 0) return 'red';
                     if (Math.abs(0 - d) <= 0.001) return 'red';
                     return 'gray';
                 })
                 .style('stroke-width', function (d:any, i:any) {
                     //if (d - Math.floor(d) == 0 && d % 5 == 0) return 2.5;
                     if (Math.abs(0 - d) <= 0.001) return 2.5;
                     return 1;
                 });

             tempAxisY.selectAll('text').attr('x', '-3').selectAll('tspan').data(function (d:any, i:any) { return [d] }).enter().append('tspan')
                 //.attr('x', function (d:any, i:any) { return -15*i; })
                 .text(function (d:any, i:any) {

                     //if (d - Math.floor(d) == 0 && d % 5 == 0) return d;
                     if (Math.abs(0 - (d % that.chart.meta.AxesSetup!.yAxis.PrimaryEvery)) <= 0.001) //GC.Axes[that.chartTypeName]['yAxis'].PrimaryEvery <= 0.001)
                         return d3.format('.0f')(d); /*Text must be a number without fraction*/
                     return '';
                 });
             this.AxisYUnit.text(that.GetLocalizedString(that.chart.meta.AxesSetup!.yAxis.Unit));

             /*Draw The Alternative y Axis*/
             if (this.chart.meta.AxesSetup!.yAxisAlternative)//GC.Axes[that.chartTypeName]["yAxisAlternative"])
             {
                 let tempAxisYAlternative = that.AxisYAlternative.call(that.PrivateData.yAxisAlternative);
                 tempAxisYAlternative.selectAll('line').attr('class', function (d:any, i:any) {
                     if (Math.abs(0 - (d % that.chart.meta.AxesSetup!.yAxisAlternative!.PrimaryEvery)) <= 0.001) //GC.Axes[that.chartTypeName]["yAxisAlternative"].PrimaryEvery <= 0.001)
                         return 'main';
                     return 'secondary';
                 });
                 tempAxisYAlternative.selectAll('g').selectAll('line.innerTick')
                     .data(function (d:any, i:any) { return [d % that.chart.meta.AxesSetup!.yAxisAlternative!.PrimaryEvery]; })//GC.Axes[that.chartTypeName]["yAxisAlternative"].PrimaryEvery] })
                     .enter().append('line')
                     .attr('class', 'innerTick')
                     .attr('x2', function (d:any, i:any) {

                         //if (d - Math.floor(d) == 0 && d % 5 == 0)return -3;
                         if (Math.abs(0 - d) <= 0.001) return -3;
                         else if (that.chart.meta.AxesSetup!.yAxisAlternative!.SecondaryEvery[d])//GC.Axes[that.chartTypeName]["yAxisAlternative"].SecondaryEvery[d])
                             return -that.padding / 3;
                             //else if (GC.Axes[that.chartTypeName]["yAxisAlternative"].HidenEvery[d])
                             //    return 0;
                         else return -3;
                     })
                     .attr('y2', 0)
                     .style('stroke', function (d:any, i:any) {
                         //if (d - Math.floor(d) == 0 && d % 5 == 0) return 'red';
                         if (Math.abs(0 - d) <= 0.001) return 'red';
                         return 'gray';
                     })
                     .style('stroke-width', function (d:any, i:any) {
                         //if (d - Math.floor(d) == 0 && d % 5 == 0) return 2.5;
                         if (Math.abs(0 - d) <= 0.001) return 2.5;
                         return 1;
                     });

                 tempAxisYAlternative.selectAll('text').selectAll('tspan').data(function (d:any, i:any) { return [d] }).enter().append('tspan')
                     //.attr('x', function (d:any, i:any) { return -15*i; })
                     .text(function (d:any, i:any) {

                         if (Math.abs(0 - (d % that.chart.meta.AxesSetup!.yAxisAlternative!.PrimaryEvery)) <= 0.001)//GC.Axes[that.chartTypeName]["yAxisAlternative"].PrimaryEvery <= 0.001)
                             return d3.format('.0f')(d); // A number without fraction
                         return '';
                     });


                 this.AxisYAlternativeUnit.text(that.GetLocalizedString(that.chart.meta.AxesSetup!.yAxisAlternative!.Unit));
             }
             else {
                 this.AxisYAlternativeUnit.text("");
             }

             /*Set the tick Size to 0 for the main line */

             let tempAxisX = that.AxisX.call(that.PrivateData.xAxis);
             tempAxisX.selectAll('g').selectAll('line')
                 .data(function (d:any, i:any) { return [d % that.chart.meta.AxesSetup!.xAxis.PrimaryEvery]; })
                 .attr('class', function (d:any, i:any) {
                     if (Math.abs(0 - d) <= 0.001)
                         return 'main';
                     return 'secondary';
                 }).attr("y2", function (d:any, i:any) {
                     if (Math.abs(0 - d) <= 0.001)
                         return -that.contentHeight;
                     return 0;
                 });



             tempAxisX.selectAll('g').selectAll('line.innerTick')
                 .data(function (d:any, i:any) { return [d % that.chart.meta.AxesSetup!.xAxis.PrimaryEvery];  /*GC.Axes[that.chartTypeName]['yAxis'].PrimaryEvery]*/ })
                 .enter().append('line')
                 .attr('class', 'innerTick')
                 .attr('y2', function (d:any, i:any) {
                     //if (d - Math.floor(d) == 0 && d % 5 == 0)return -3;
                     if (Math.abs(0 - d) <= 0.001) return 3;
                     else if (that.chart.meta.AxesSetup!.xAxis.SecondaryEvery[d]) //GC.Axes[that.chartTypeName]['yAxis'].SecondaryEvery[d])
                         return that.padding / 3;
                     else return 3;
                 })
                 .attr('x2', 0)
                 .style('stroke', function (d:any, i:any) {
                     //if (d - Math.floor(d) == 0 && d % 5 == 0) return 'red';
                     if (Math.abs(0 - d) <= 0.001) return 'red';
                     return 'gray';
                 })
                 .style('stroke-width', function (d:any, i:any) {
                     //if (d - Math.floor(d) == 0 && d % 5 == 0) return 2.5;
                     if (Math.abs(0 - d) <= 0.001) return 2.5;
                     return 1;
                 });

             tempAxisX.selectAll('text').selectAll('tspan').data(function (d:any, i:any) { return [d] }).enter().append('tspan')
                 //.attr('x', function (d:any, i:any) { return -15*i; })
                 .text(function (d:any, i:any) {

                     //if (d - Math.floor(d) == 0 && d % 5 == 0) return d;
                     if (Math.abs(0 - (d % that.chart.meta.AxesSetup!.xAxis.PrimaryEvery)) <= 0.001) //GC.Axes[that.chartTypeName]['yAxis'].PrimaryEvery <= 0.001)
                     {
                         if (that.chart.meta.AxesSetup!.xAxis.Text) {
                             return d3.format('.0f')(that.chart.meta.AxesSetup!.xAxis.Text(d)); /*Text must be a number without fraction*/
                         }
                         return d3.format('.0f')(d); /*Text must be a number without fraction*/
                     }
                     return '';
                 });

             this.AxisXUnit.text(that.GetLocalizedString(that.chart.meta.AxesSetup!.xAxis.Unit));

             /*Draw Axes X Alternative*/

             if (this.chart.meta.AxesSetup!.xAxisAlternative)//GC.Axes[that.chartTypeName]["yAxisAlternative"])
             {
                 let tempAxisXAlternative = that.AxisXAlternative.call(that.PrivateData.xAxisAlternative);
                 tempAxisXAlternative.selectAll('line').attr('class', function (d:any, i:any) {

                     if (Math.abs(0 - (d % that.chart.meta.AxesSetup!.xAxisAlternative.PrimaryEvery)) <= 0.001) //GC.Axes[that.chartTypeName]["yAxisAlternative"].PrimaryEvery <= 0.001)
                         return 'main';
                     return 'secondary';
                 });
                 tempAxisXAlternative.selectAll('g').selectAll('line.innerTick')
                     .data(function (d:any, i:any) { return [d % that.chart.meta.AxesSetup!.xAxisAlternative.PrimaryEvery]; })//GC.Axes[that.chartTypeName]["yAxisAlternative"].PrimaryEvery] })
                     .enter().append('line')
                     .attr('class', 'innerTick')
                     .attr('y2', function (d:any, i:any) {

                         //if (d - Math.floor(d) == 0 && d % 5 == 0)return -3;
                         if (Math.abs(0 - d) <= 0.001) return 3;
                         else if (that.chart.meta.AxesSetup!.xAxisAlternative.SecondaryEvery[d])//GC.Axes[that.chartTypeName]["yAxisAlternative"].SecondaryEvery[d])
                             return that.padding / 3;
                             //else if (GC.Axes[that.chartTypeName]["yAxisAlternative"].HidenEvery[d])
                             //    return 0;
                         else return 3;
                     })
                     .attr('x2', 0)
                     .style('stroke', function (d:any, i:any) {
                         //if (d - Math.floor(d) == 0 && d % 5 == 0) return 'red';
                         if (Math.abs(0 - d) <= 0.001) return 'red';
                         return 'gray';
                     })
                     .style('stroke-width', function (d:any, i:any) {
                         //if (d - Math.floor(d) == 0 && d % 5 == 0) return 2.5;
                         if (Math.abs(0 - d) <= 0.001) return 2.5;
                         return 1;
                     });

                 tempAxisXAlternative.selectAll('text').selectAll('tspan').data(function (d:any, i:any) { return [d] }).enter().append('tspan')
                     //.attr('x', function (d:any, i:any) { return -15*i; })
                     .text(function (d:any, i:any) {

                         if (Math.abs(0 - (d % that.chart.meta.AxesSetup!.xAxisAlternative.PrimaryEvery)) <= 0.001)//GC.Axes[that.chartTypeName]["yAxisAlternative"].PrimaryEvery <= 0.001)
                         {
                             if (that.chart.meta.AxesSetup!.xAxisAlternative.Text) {
                                 //return d3.format('1f')(
                                 return d3.format('.0f') (that.chart.meta.AxesSetup!.xAxisAlternative.Text(d));
                                 //); /*Text must be a number without fraction*/
                             }
                             return d3.format('.0f')(d);
                         }
                         return '';
                     });
                 this.AxisXAlternativeUnit.text(that.GetLocalizedString(that.chart.meta.AxesSetup!.xAxisAlternative.Unit));
             }
             else {
                 this.AxisXAlternativeUnit.text("");
             }
             //if (that.chart.meta.Y_AXIS_Unit == 'kg') {
             //    that.AxisYAlternative.call(that.PrivateData.yAxisAlternative).selectAll('text').text(function (d:any, i:any) {
             //        let output = d * GC.Units['kg']['lb'];
             //        if (output - Math.floor(output) == 0) {
             //            let frmt1 = d3.format("1f")
             //            return frmt1(output);
             //        }
             //        return ''
             //    })
             //}
             //else if (that.chart.meta.Y_AXIS_Unit == 'cm') {
             //    that.AxisYAlternative.call(that.PrivateData.yAxisAlternative).selectAll('text').text(function (d:any, i:any) {
             //        let output= d * GC.Units['cm']['in']
             //        if (output - Math.floor(output) == 0) {
             //            let frmt1 = d3.format("1f")
             //            return frmt1(output);
             //        }
             //        return '';
             //    })
             //}

             //AxisYTicks.exit().remove();
         };
        private DrawAxes() {
            return;
            let that = this;
            //this.PrivateData.xAxis = d3.svg.axis().scale(that.xScale);

            if (that.chart.meta.X_Axis.AXIS_Name == ChartAxisNames.Age) {

                //this.PrivateData.xAxis.ticks((that.xMax - that.xMin + 1)).tickFormat('');
                //this.PrivateData.yAxis.ticks((that.xMax - that.xMin + 1) * that.contentHeight / that.contentWidth);

                //that.AxisX.selectAll("*").remove();
                that.AxisX.call(this.PrivateData.xAxis)
                .selectAll('text')
                .selectAll('tspan')
                .data(function (d:any, i:any) {
                    let fmt0 = d3.format('.0f');
                    let fmt1 = d3.format('1.1f');

                    if (d == 0) {
                        return [0, 'Birth']
                    }
                    else if (d % 12 == 0) {
                        return [0, fmt0(d / 12) + ' Year'];
                    }
                    else if (Math.floor(d % 12) === (d % 12)) /*For months without fraction, like 25,37*/ {
                        return [fmt0(d % 12), ''];
                    }
                    //else if (d % .5 == 0)/*Form months with fractin like 25.5*/ {
                    return [fmt1(d % 12), ''];// Math.floor(d / 12)];
                    //}
                    //return ['', ''];// Math.floor(d / 12)];

                })
                .enter()
                .append('tspan')
                .attr('x', 0)
                //.attr('dx',function (d:any, i:any) { return (-2 * i) + 'em'; }) 
                .attr('dy', '1em')// function (d:any, i:any) { return (-2 * i) + 'em'; })

                .text(String);
            }
            else {

                //xAxis.scale(that.xScale);
                //if (that.chart.meta.X_AXIS_Name == "Year") {
                //    this.PrivateData.xAxis/*.orient("bottom")*/
                //      .ticks((that.xMax - that.xMin + 1));
                //    this.PrivateData.yAxis.ticks((that.xMax - that.xMin + 1) * that.contentHeight / that.contentWidth);
                //}
                //else {
                //    this.PrivateData.xAxis/*.orient("bottom")*/
                //      .ticks(20);

                //    this.PrivateData.yAxis.ticks((20) * that.contentHeight / that.contentWidth);
                //}

                //that.svg.append("g")
                //  .attr("class", "axis")
                //  .attr("transform", "translate(" + 0 + "," + (that.height - that.padding) + ")")
                //that.AxisX.selectAll("*").remove();
                //that.AxisX.call(that.PrivateData.xAxis);
                that.AxisX.call(that.PrivateData.xAxis).selectAll('text').text(function (d:any, i:any) { return d; });
                //.selectAll('text').data(function (d:any, i:any) { return [d]; }).enter().append('tspan')
                //.text(function (d:any, i:any) { });
            }




        };
        private DrawAxesText() {
            let that = this;
            //that.svg.select('text.Y_AXIS_DSC').remove();
            //that.svg.select('text.X_AXIS_DSC').remove();
            // Axes text
            let Y_AXIS_DSC = this.GetLocalizedString(that.chart.meta.Y_Axis.AXIS_DSC);// ? chart.meta.Y_AXIS_DSC : "Weight (kg)";

            this.AxisYText.style("direction", that.direction).text(Y_AXIS_DSC);

            let X_AXIS_DSC = this.GetLocalizedString(that.chart.meta.X_Axis.AXIS_DSC);// ? metaData.X_AXIS_DSC : "Age (months)";

            this.AxisXText.style("direction", that.direction).text(X_AXIS_DSC);
        };
        public Display(chartMetaData:IChartMetaDataByGender<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,common.Genders,GrowthChartResults>) {

            // chartMetaData.meta.AxesSetup = AxesSetupsLookup [chartMetaData.meta.AxesSetupID];

            let that = this;
            this._percentileZscoreCalculator.SetChart(chartMetaData);
            // this.chartTypeName = chartTypeName;
            /*min and max values on the curves*/



            // Graph formatting, in pixels
            //that.width = 800;
            //that.height = 450;

            //let extraRightPadding = 40; // For line labels ... "severely malnourished" goes offscreen
            //// clear exiting growth chart svg .. allows to reset graph with new background
            //d3.select(this.selector).select(".growth_chart_main_svg").remove();
            //this.svg = d3.select(this.selector).append("svg")
            //  //.attr("width", width + extraRightPadding)
            //  //.attr("height", height)
            //  .attr("viewBox", "0 0 " + (that.width + extraRightPadding) + " " + that.height + "")
            //  .attr("preserveAspectRatio", "xMidYMid meet")
            //  .attr("class", "growth_chart_main_svg");

            this.chart =chartMetaData;// this.LoadChartType(this.chartTypeName);
            // this.LocalizeChart(this.language_code);



            // let curvesData =
            this.GetCurvesData();
            // Graph scale; domain and range
            /*These functions map an input domain to an output range.*/
            //this.xScale = d3.scale.linear()
            //  .domain([this.xMin, this.xMax])
            //  .range([that.padding, that.width - that.padding]);
            this.xScale.domain([this.xMin, this.xMax]);
            /*These functions map an input domain to an output range.*/
            //this.yScale = d3.scale.linear()
            //  .domain([this.yMin, this.yMax])
            //  .range([that.height - that.padding, that.padding]);
            this.yScale.domain([this.yMin, this.yMax]);
            //let axesSetup = that.chart.meta.AxesSetup;//GC.Axes[this.chartTypeName];




            if (that.chart.meta.AxesSetup!.yAxisAlternative) {
                
                this.yMinAlternative = (this.yMin * common.GeneralUnitConverter[that.chart.meta.AxesSetup!.yAxis.Unit]![that.chart.meta.AxesSetup!.yAxisAlternative!.Unit]!);
                this.yMaxAlternative = (this.yMax * common.GeneralUnitConverter[that.chart.meta.AxesSetup!.yAxis.Unit]![that.chart.meta.AxesSetup!.yAxisAlternative!.Unit]!);
                this.yScaleAlternative.domain([this.yMinAlternative, this.yMaxAlternative]);
            }
            if (that.chart.meta.AxesSetup!.xAxisAlternative) {
                this.xMinAlternative = (this.xMin * common.GeneralUnitConverter[that.chart.meta.AxesSetup!.xAxis.Unit]![that.chart.meta.AxesSetup!.xAxisAlternative.Unit]!);
                this.xMaxAlternative = (this.xMax * common.GeneralUnitConverter[that.chart.meta.AxesSetup!.xAxis.Unit]![that.chart.meta.AxesSetup!.xAxisAlternative.Unit]!);
                this.xScaleAlternative.domain([this.xMinAlternative, this.xMaxAlternative]);
            }

            //if (this.chart.meta.Y_AXIS_Unit == 'cm')
            //{
            //    this.yScaleAlternative.domain([(this.yMin * GC.Units['cm']['in']), (this.yMax * GC.Units['cm']['in'])]);
            //}
            this.DrawCurves();
            this.PrepareAxes();
            this.DrawAxisY();
            this.DrawAxes();
            this.DrawAxesText();

            this.DrawCurvesLabel();
            this.DrawHeader();

            //drawUserData(svg, xScale, yScale, chart);
            this.displayed = true;
            // TODO: xScaleAlternative.
            /*Stop Zooming
            this.zoom.x(this.xScale);
            this.zoomAlternative.x(this.xScaleAlternative);
            */
            this.ApplyStyle();
            this.ExcludeLines(this.excludedLines)
        };
      


        public Clear () {
            let that = this;
            let ClearCurves = function () {
                that.svg.selectAll("path.area").remove();
                that.svg.selectAll("path.line").remove();
            };
            let ClearAxes = function () {
                that.AxisX.selectAll("*").remove();
                that.AxisY.selectAll("*").remove();
                that.AxisYRight.selectAll("*").remove();
                
                that.AxisXAlternative.selectAll("*").remove();
                that.AxisYAlternative.selectAll("*").remove();

                that.AxisXAlternativeUnit.selectAll("*").remove();
                that.AxisXAlternativeUnit.selectAll("*").remove();

                
            };
            let ClearAxesText = function () {
                that.AxisYText.text('');
                that.AxisXText.text('');
                
                that.AxisXUnit.text('');
                that.AxisYUnit.text('');
                that.AxisXAlternativeUnit.text('');
                that.AxisYAlternativeUnit.text('');
                // that.AxisXUnit.selectAll("*").remove();
                // that.AxisYUnit.selectAll("*").remove();
            };
            let ClearCurvesLabel = function () {
                that.svg.selectAll("text.line-label").remove();
            };
            let ClearHeader = function () {
                that.titleText.text("");
            };
            let ClearPoints = function () {
                that.svg.selectAll(".dot").remove();
                that.svg.selectAll(".dotSelected").remove();
            };
            ClearPoints();
            this.DrawPins([])
            //this.SetToolTipText(null);
            //this.ClearLinesFromPointToAxes();
            this.DrawLinesFromPointToAxes([]);
            ClearHeader();
            ClearCurvesLabel();
            ClearAxesText();
            ClearAxes();
            ClearCurves();
            this.displayed = false;


        };
        private SelectNextPoint () {

            if (this.dotsLength > -1) {
                this.selectedDotIndex++;
                if (this.selectedDotIndex > this.dotsLength) {
                    this.selectedDotIndex = 0;
                }
            }
        };
        private SelectPrevPoint () {
            if (this.dotsLength > -1) {
                this.selectedDotIndex--;
                if (this.selectedDotIndex < 0) {
                    this.selectedDotIndex = this.dotsLength - 1;
                }
            }
        };

        //SelectPointByPoint: function (indx) {
        //    this.svg.select('.dotSelected')
        //        .attr('class', function (d:any, i:any) {
        //            return "dot";
        //        }
        //        );

        //    this.svg.select('.dot' + indx).attr('class', 'dotSelected dot' + indx);

        //}

        // It will take an array of objects.
        public SelectPointByID (ids:number[]) {
            let that = this;
            let selectedIDs = ids;
            //this.svg.selectAll('.dotSelected').attr('class', 'dot');
            that.ClearLinesFromPointToAxes();
            //that.SetToolTipText(null);
            that.DrawPins([]);
            that.svg.selectAll('.dot').attr('class', 'dot');
            if (!ids || ids.length <= 0) {
                //this.DrawPins([]);
                return
            };

            let existedDots = [];
            for (let seq = 0; seq < ids.length; seq++) {
                let selectedDot = that.svg.select(".dot[id='" + ids[seq] + "']").attr('class', 'dot dotSelected').data();
                if (selectedDot && selectedDot.length > 0 && selectedDot[0]) {
                    //this.SetToolTipText(selectedDot[0]);
                    // this.DrawLinesFromPointToAxes(selectedDot[0])
                    existedDots.push(selectedDot[0]);
                }
            }
            that.DrawLinesFromPointToAxes(existedDots);
            //this.SetToolTipText(existedDots);
            that.SetPercentileOfArray(existedDots);
            that.DrawPins(existedDots);
            that.ApplyUserDataStyle();
            //let selectedDot = this.svg.select(".dot[id='" + id + "']").attr('class', 'dotSelected').data();
            //if (selectedDot && selectedDot.length > 0) {
            //    this.SetToolTipText(selectedDot[0]);
            //    this.DrawLinesFromPointToAxes(selectedDot[0])
            //}
            //else {
            //    // Force remove any extra shape
            //    this.SetToolTipText(null);
            //    this.DrawLinesFromPointToAxes(null);
            //}

            //this.svg.select('.dot[data-x='+arr[0]+',data-]')
        };
        //DrawToolTip: function () {
        //    let tooltipOffset = this.padding + 10;
        //    let tooltipGroup = this.svg.append("g");

        //    let tooltipBackground = tooltipGroup.append("rect")
        //      // .attr("class","tooltip")
        //      .attr("x", tooltipOffset)
        //      .attr("y", tooltipOffset)
        //      .attr("width", 0)
        //      .attr("height", 0)
        //      .attr("class", "tooltipTextBackground")


        //    this.tooltipText = tooltipGroup.append("text")
        //      .attr("x", tooltipOffset)
        //      .attr("y", tooltipOffset)
        //      .attr("class", "tooltipText")
        //      .style("font-size", "14px")
        //      // .style("background-color","gray")
        //      // .text("(Move mouse over a data point to see details)");
        //      .text("");
        //},
        private ClearLinesFromPointToAxes () {
            this.rectangleToAxis.selectAll(".rect-to-axis")
               .data([])
             .exit().remove();
        };

        private DrawPins (d:IMappingFunctionOutput[]) {
            // console.log(JSON.stringify(d));
            /*
                d:  0 --> x
                    1 --> y
                    2 --> id
                    3 --> Result
            */
            let that = this;

            let pins = that.Content.selectAll('g.pin').data(d);

            let pinsEnter = pins.enter().append('g')
                .attr('class', 'pin')
                .attr('transform', function (d:IMappingFunctionOutput) { return "translate(" + that.xScale(d.X) + "," + (that.yScale(d.Y) - 50) + ")" });
            //.attr("clip-path", "url(#clip)");

            pinsEnter.append('path').attr('fill', '#d53').attr('stroke', 'black').attr('stroke-width', '1').attr('d', 'M0,47 Q0,28 10,15 A15,15 0,1,0 -10,15 Q0,28 0,47');

            pinsEnter.append('text').attr('dx', '-10').attr('dy', '10').text(function (d:IMappingFunctionOutput) { 
                // d.ResultValue
                return that._percentileZscoreCalculator.GetPercentile(d);
             }).attr('fill', 'yellow');

            pins.exit().remove();

        };
        private DrawLinesFromPointToAxes (d:IMappingFunctionOutput[]) {

            let that = this;
            let rectangles = this.rectangleToAxis.selectAll(".rect-to-axis").data(d);
            rectangles.enter().append('path')
                .attr('class', 'rect-to-axis')
            .attr("d", function (d:IMappingFunctionOutput) {


                return "M" + that.xScale(d.X) + "," + that.yScale(that.yMin) + "L" + that.xScale(d.X) + "," + that.yScale(d.Y) + "L" + that.xScale(that.xMin) + "," + that.yScale(d.Y);
            });
            //rectangles.enter().append('rect').attr("class", "rect-to-axis")
            //    .attr("fill", "rgba(0,0,0,0)")
            //    .attr("stroke", "rgba(20,20,20, 1)")
            //      .style("stroke-dasharray", function (point) {
            //          let dottedSegmentLength = 3;  // used below, too, for linesToAxis
            //          let linesToAxisWidth = that.xScale(point[0]) /*- that.padding*/;
            //          let linesToAxisHeight = that.height - that.yScale(point[1]) /*- that.padding*/;
            //          let halfRectLength = linesToAxisWidth + linesToAxisHeight;
            //          //halfRect = halfRectLength.toString();

            //          // Draw top and right sides of rectangle as dotted. Hide bottom and left sides
            //          let dottedSegments = Math.floor(halfRectLength / dottedSegmentLength);
            //          let nonDottedLength = halfRectLength * 2; // + (dottedSegments % dottedSegmentLength);

            //          let dashArrayStroke = [];

            //          for (let i = 0; i < dottedSegments; i++) {
            //              dashArrayStroke.push(dottedSegmentLength);
            //          }
            //          // if even number, add extra filler segment to make sure 2nd half of rectangle is hidden
            //          if ((dottedSegments % 2) === 0) {
            //              extraSegmentLength = halfRectLength - (dottedSegments * dottedSegmentLength);
            //              dashArrayStroke.push(extraSegmentLength);
            //              dashArrayStroke.push(nonDottedLength);
            //          } else {
            //              // extraSegmentLength = halfRectLength - (dottedSegments*dottedSegmentLength);
            //              dashArrayStroke.push(nonDottedLength);
            //          }
            //          return dashArrayStroke.toString();
            //      })
            //  .attr("x", 0/*this.padding*/)
            //  .attr("y", function (point) { return that.yScale(point[1]); })
            //  .attr("width", function (point) { let linesToAxisWidth = that.xScale(point[0]) /*- that.padding*/; return linesToAxisWidth; })
            //  .attr("height", function (point) { let linesToAxisHeight = that.height - that.yScale(point[1]) /*- that.padding*/; return linesToAxisHeight; });

            rectangles.exit().remove();

            /*
            this.rectangleToAxis.selectAll(".rect-to-axis")
               .data([])
             .exit().remove();

            if (!d || d.lenght <= 0) return;
            let that = this;
            this.rectangleToAxis.selectAll(".rect-to-axis")
              .data(d)
             .enter().append("rect")
              .attr("class", "rect-to-axis")
                .attr("fill", "rgba(0,0,0,0)")
                .attr("stroke", "rgba(20,20,20, 1)")
                  .style("stroke-dasharray", function (point) {
                      let dottedSegmentLength = 3;  // used below, too, for linesToAxis
                      let linesToAxisWidth = that.xScale(point[0]) - that.padding;
                      let linesToAxisHeight = that.height - that.yScale(point[1]) - that.padding;
                      let halfRectLength = linesToAxisWidth + linesToAxisHeight;
                      //halfRect = halfRectLength.toString();

                      // Draw top and right sides of rectangle as dotted. Hide bottom and left sides
                      let dottedSegments = Math.floor(halfRectLength / dottedSegmentLength);
                      let nonDottedLength = halfRectLength * 2; // + (dottedSegments % dottedSegmentLength);

                      let dashArrayStroke = [];

                      for (let i = 0; i < dottedSegments; i++) {
                          dashArrayStroke.push(dottedSegmentLength);
                      }
                      // if even number, add extra filler segment to make sure 2nd half of rectangle is hidden
                      if ((dottedSegments % 2) === 0) {
                          extraSegmentLength = halfRectLength - (dottedSegments * dottedSegmentLength);
                          dashArrayStroke.push(extraSegmentLength);
                          dashArrayStroke.push(nonDottedLength);
                      } else {
                          // extraSegmentLength = halfRectLength - (dottedSegments*dottedSegmentLength);
                          dashArrayStroke.push(nonDottedLength);
                      }
                      return dashArrayStroke.toString();
                  })
              .attr("x", this.padding)
              .attr("y", function (point) {
                  return that.yScale(point[1])
              })
              .attr("width", function (point) {
                  let linesToAxisWidth = that.xScale(point[0]) - that.padding;
                  return linesToAxisWidth;
              })
              .attr("height", function (point) {
                  let linesToAxisHeight = that.height - that.yScale(point[1]) - that.padding;
                  return linesToAxisHeight;
              });
              */
            // Horizontal Line
            //this.rectangleToAxis.selectAll(".rect-to-axis").data([d]).enter().append("line")          // attach a line
            //     .attr("class", "rect-to-axis")
            //    .attr("fill", "rgba(0,0,0,0)")
            //    .attr("stroke", "rgba(20,20,20, .8)")
            //.style("stroke-dasharray", ("3, 3")) 
            //.attr("x1", this.xScale(d[0]))     // x position of the first end of the line
            //.attr("y1", this.yScale(d[1]))      // y position of the first end of the line
            //.attr("x2", this.xScale(d[0]))     // x position of the second end of the line
            //.attr("y2", this.height - this.padding);    // y position of the second end of the line


            // Vertical Line
            //this.rectangleToAxis.selectAll(".rect-to-axis").data([d]).enter().append("line")          // attach a line
            //     .attr("class", "rect-to-axis")
            //    .attr("fill", "rgba(0,0,0,0)")
            //    .attr("stroke", "rgba(20,20,20, .8)")
            //.style("stroke-dasharray", ("3, 3"))
            //.attr("x1", this.xScale(d[0]))     // x position of the first end of the line
            //.attr("y1", this.yScale(d[1]))      // y position of the first end of the line
            //.attr("x2", this.padding)     // x position of the second end of the line
            //.attr("y2", this.yScale(d[1]));    // y position of the second end of the line


        };
        private SetToolTipText (Ds:any) {
            /*This method is deprecated.*/
            return;

            //// this.tooltipText.text("");
            //this.tooltipText
            //   .selectAll('tspan')
            //      .data([])
            // .exit()
            //    .remove();

            //this.svg.selectAll(".tooltipTextBackground").attr("width", 0).attr("height", 0)

            //if (!Ds || Ds.length <= 0) return;

            //let that = this;

            //let textArr = [];

            //for (let seq = 0; seq < Ds.length; seq++) {
            //    let text = "";
            //    let d = Ds[seq];
            //    let x_value = parseFloat(d[0]).toFixed(2);
            //    let y_value = parseFloat(d[1]).toFixed(2);
            //    let x_text = '';
            //    //if (this.chart.meta.X_AXIS_Name == "Month") {
            //    //    x_text = this.GetLocalizedString(this.chart.meta.X_AXIS_DSC) + ":" + this.GetAgeTextMonths(x_value);
            //    //}
            //    //else if (this.chart.meta.X_AXIS_Name == "Year") {
            //    //    x_text = this.GetLocalizedString(this.chart.meta.X_AXIS_DSC) + ":" + this.GetAgeTextYears(x_value);
            //    //}
            //    //else {
            //    x_text = this.GetLocalizedString(this.chart.meta.X_AXIS_DSC) + ":" + x_value + " " + this.GetLocalizedString(this.chart.meta.X_AXIS_Unit);

            //    //}
            //    let y_text = this.GetLocalizedString(this.chart.meta.Y_AXIS_DSC) + ": " + y_value + " " + this.GetLocalizedString(this.chart.meta.Y_AXIS_Unit);
            //    text += x_text + ";" + y_text;

            //    let output_Value = Ds[seq]['Result'] = this.GetPercentile(d);
            //    if (output_Value) {
            //        if (this.Output_Unit == "percentile") {
            //            text += ';' + this.GetLocalizedString('percentile') + ':' + output_Value;
            //        }
            //        else if (this.Output_Unit == "z-score") {
            //            text += ';' + this.GetLocalizedString('z-score') + ':' + output_Value;
            //        }
            //    }
            //    //return text;
            //    //text += '<br/>';
            //    textArr.push(text);
            //}

            //let tooltipOffset = this.padding + 10;

            //this.tooltipText
            //    //.selectAll('text')
            //    .selectAll('tspan')
            //    .data(textArr)
            //    .enter()
            //    .append('tspan')
            //    .attr('x', tooltipOffset)
            //    .attr('dy', "1em")// function (d:any, i:any) { return i == 0 ? "0em" : "1em"; })
            //    .text(String)
            //    .style("direction", this.direction);


            //let bbox = this.svg.select(".tooltipText")[0][0].getBBox();

            //let tooltipHeightPadding = 5;
            //let tooltipWidthPadding = 4;

            //if (this.direction == "rtl") {
            //    this.tooltipText.attr("x", bbox.width + tooltipOffset);
            //    this.tooltipText.selectAll('tspan').attr("x", bbox.width + tooltipOffset);
            //}
            //else {
            //    this.tooltipText.attr("x", tooltipOffset);
            //    this.tooltipText.selectAll('tspan').attr("x", tooltipOffset);
            //}


            //this.svg.selectAll(".tooltipTextBackground")
            //  .attr("width", bbox.width + tooltipWidthPadding * 2)
            //  .attr("height", bbox.height + tooltipHeightPadding)
            //    .attr("y", tooltipOffset)
            //      .attr("x", tooltipOffset - tooltipWidthPadding);
        };

        private GetAgeTextMonths (months:any) {
            let y = Math.floor(months / 12);
            let m = months - (y * 12);
            m = Math.floor(m);//.toFixed(0);

            if (y > 0) {
                return y + this.GetLocalizedString('year') + ',' + m + this.GetLocalizedString('month');
            } else {
                return m + this.GetLocalizedString('month');
            }
        };
        private GetAgeTextYears (years:any) {
            //let y = Math.floor(months / 12);
            let y = Math.floor(years);
            let m = (years - y) * 12;
            m = Math.floor(m);//m.toFixed(0);

            if (y > 0) {
                return y + this.GetLocalizedString('year') + ',' + m + this.GetLocalizedString('month');
            } else {
                return m + this.GetLocalizedString('month');
            }
        };
        // public GetPercentile_ZScore_OfNative (nativeRecord:common.VisitItem) 
        //   {
        //     //   console.log(JSON.stringify(nativeRecord));
        //     //  let d = MappingFunctionsLookup[this.chart.meta.MapFunctionID](nativeRecord);
        //     let d = this.chart.meta.MapFunction(nativeRecord);
        //      //if (this.chart.meta.X_AXIS_Name == "Year") {
        //      //    d[0] = d[0] / 12;
        //      //}
        //     //  console.log(JSON.stringify(d));
        //      return this.GetPercentile(d);
        //  };
         private SetPercentileOfArray= function (arr:any) {
            /*No need to set the Percentile or zscore as it will be set in the angularjs directive for all points*/
            return;
            //for (let index = 0; index < arr.length; index++) {
            //    arr[index]['Result'] = this.GetPercentile(arr[index]);
            //}
        };
        
       

        // LocalizeChart (language_code) {
        //     //if (this.language_code == language_code
        //     //    && this.currentLocale.length > 0) {
        //     //    return;
        //     //}
        //     //else {
        //     //    this.language_code = language_code;
        //     //};
        //     this.language_code = language_code;
        //     if (!language_code || language_code.length <= 0) {
        //         this.currentLocale = [];
        //         return;
        //     }
        //     this.currentLocale = GC.Locales[language_code];
        //     if ((!this.currentLocale || this.currentLocale.length <= 0)
        //             && language_code.indexOf('_'))
        //         this.currentLocale = GC.Locales[language_code.split("_")[0]];

        //     if ((!this.currentLocale || this.currentLocale.length <= 0)
        //            && language_code.indexOf('-'))
        //         this.currentLocale = GC.Locales[language_code.split("-")[0]];
        //     if ((!this.currentLocale || this.currentLocale.length <= 0))
        //         return;

        //     //this.chart.meta.title = this.GetLocalizedString(this.chartTypeName); /*Get Localization of the title*/

        //     //chart.meta.X_AXIS_Name = scope.$root.getLocalizedString(chart.meta.X_AXIS_Name); /*Get Localization of the title*/
        //     //this.chart.meta.X_AXIS_DSC_Locale = this.GetLocalizedString(this.chart.meta.X_AXIS_DSC); /*Get Localization of the title*/
        //     //this.chart.meta.X_AXIS_Unit_Locale = this.GetLocalizedString(this.chart.meta.X_AXIS_Unit); /*Get Localization of the title*/

        //     ////chart.meta.Y_AXIS_Name = scope.$root.getLocalizedString(chart.meta.Y_AXIS_Name); /*Get Localization of the title*/
        //     //this.chart.meta.Y_AXIS_DSC_Locale = this.GetLocalizedString(this.chart.meta.Y_AXIS_DSC); /*Get Localization of the title*/
        //     //this.chart.meta.Y_AXIS_Unit_Locale = this.GetLocalizedString(this.chart.meta.Y_AXIS_Unit); /*Get Localization of the title*/

        //     this.direction = this.GetLocalizedString("direction");
        // };
        private GetLocalizedString=function (token:any) {
            // let value = null;
            // if (this.currentLocale && this.currentLocale.length > 0) {
            //     value = $.grep(this.currentLocale, function (n, i) { return n.key == token; });
            // }
            // if (value && value.length > 0)
            //     return value[0].value;

            return token;

        };
        private ApplyStyle () {

            /*This method is used to apply the style so incase the svg was copied, the style will be also copied.*/
            /*Write the most general first then the more specific later.*/


            //.axis path,.axis line {fill: none;stroke: black;shape-rendering: crispEdges;}
            this.svg.selectAll('.axis path,.axis line').attr('fill', 'none').attr('stroke', 'black').attr('shape-rendering', 'crispEdges');

            //.line {fill: none;stroke: gray;stroke-width: 1.5px;}
            this.svg.selectAll('.line').attr('fill', 'none').attr('stroke', 'gray').attr('stroke-width', '1.5px');

            //text {font-family: sans-serif;font-size: 11px;}
            //text.label {fill:black;/*stroke-width:2px;*/font-size:8px;}
            this.svg.selectAll('text').style('font-family', 'sans-serif').style('font-size', '11px');
            this.svg.selectAll('text.label').attr('fill', 'black').style('font-size', '8px');


            //.growth_chart_main_container>svg.growth_chart_main_svg {
            //    position: absolute;
            //    top: 0;
            //    left: 0;
            //    min-height:100%; /* Fixing Issue of the height of the SVG is fixed to zero. The style of the container must be set correctly. */
            //}
            this.svg.style('position', 'absolute').style('top', '0').style('left', '0').style('min-height', '100%');


            //.backgroundRect {fill: rgba(255,255,255,1);stroke: rgba(20,20,20, .8);}
            //.tooltipTextBackground {fill: rgba(237,126,30,0.5);/*stroke: rgba(20,20,20, .8);*/}
            this.svg.selectAll('.backgroundRect').attr('fill', 'rgba(255,255,255,1)').attr('stroke', 'rgba(20,20,20, .8)');
            this.svg.selectAll('.tooltipTextBackground').attr('fill', 'rgba(237,126,30,0.5)');

            /*This style must be applied before coloring the SD and P*/
            //.axis.y.left>.tick>line,.axis.x.bottom>.tick>line {fill:none;stroke:none;}
            //.axis.x>.tick>line.main,.axis.y.left>.tick>line.main{fill:none!important;stroke:#fff!important;opacity:0.2!important;stroke-width:2px!important;}
            this.svg.selectAll('.axis.y.left>.tick>line,.axis.x.bottom>.tick>line').attr('fill', 'none').attr('stroke', 'none');
            this.svg.selectAll('.axis.x>.tick>line.main,.axis.y.left>.tick>line.main').attr('fill', 'none').attr('stroke', '#fff').attr('opacity', '0.2').attr('stroke-width', '2px');


            //.SD0,.P50 {stroke: #00FF00 !important; /*green*/}
            //.SD0P5neg,.SD0P5,.P75,.P25 {stroke: #8FFF00 !important; /*light green*/}
            //.SD1neg,.SD1,.P85,.P15 {stroke: #FFFF00 !important; /*yellow*/}
            //.P90,.P10 {stroke: #FFAF00 !important;}
            //.SD1P5neg,.SD1P5,.P95,.P5 {stroke: #FF6F00 !important; /*Orange*/}
            //.SD2neg,.SD2,.P97,.P3 {stroke: #FF0000 !important; /*red*/}
            //.P99,.P1 {stroke: dimgray !important; /*White*/}
            //.SD3neg, .SD3,.P999,.P01 {stroke: #000000 !important; /*Black*/}
            this.svg.selectAll('.SD0,.P50').attr('stroke', '#00FF00');
            this.svg.selectAll('.SD0P5neg,.SD0P5,.P75,.P25').attr('stroke', '#8FFF00');
            this.svg.selectAll('.SD1neg,.SD1,.P85,.P15').attr('stroke', '#FFFF00');
            this.svg.selectAll('.P90,.P10').attr('stroke', '#FFAF00');
            this.svg.selectAll('.SD1P5neg,.SD1P5,.P95,.P5').attr('stroke', '#FF6F00');
            this.svg.selectAll('.SD2neg,.SD2,.P97,.P3').attr('stroke', '#FF0000');
            this.svg.selectAll('.P99,.P1').attr('stroke', '#dimgray');
            this.svg.selectAll('.SD3neg,.SD3,.P999,.P01').attr('stroke', '#000000');

            //.areamale {fill: steelblue;/*opacity: 0.1;*/}
            //.areafemale {fill: lightpink;/*opacity: 0.1;*/}
            /*These styles must be at the end.*/
            this.svg.selectAll('.areamale').attr('fill', 'steelblue');
            this.svg.selectAll('.areafemale').attr('fill', 'lightpink');
            this.ApplyUserDataStyle();
        }
        private ApplyUserDataStyle () {

            /*The following Sytel must be applied after drawing user data (every time).*/
            //.pLine {fill: none;stroke: black;stroke-width: 1.5px;}
            //.dot {fill: black;stroke: black;stroke-width: 1.5px;}
            //.dotSelected {fill: rgb(237,126,30);stroke: rgb(237,126,30);stroke-width: 1.5px;z-index:1000!important;}
            //.rect-to-axis {fill: rgba(0,0,0,0);stroke: rgba(0,0,0, 1);stroke-dasharray:6;}
            this.svg.selectAll('.pLine').attr('fill', 'none').attr('stroke', 'black').attr('stroke-width', '1.5px');
            this.svg.selectAll('.dot').attr('fill', 'black').attr('stroke', 'black').attr('stroke-width', '1.5px');
            this.svg.selectAll('.dotSelected').attr('fill', 'rgb(237,126,30)').attr('stroke', 'rgb(237,126,30)').attr('stroke-width', '1.5px').style('z-index', '1000');
            this.svg.selectAll('.rect-to-axis').attr('fill', 'rgba(0,0,0,0)').attr('stroke', 'rgba(0,0,0, 1)').attr('stroke-dasharray', '6');
            this.svg.selectAll('g.pin').style('z-index', '1000');
            this.svg.selectAll('g.pin>text').attr('fill', 'yellow').style('font-size', '8px');
        };
        private ExcludeLines (excludedLines:any) {
            // this.excludedLines = excludedLines;
            // $('.curve').css('visibility', 'visible');
            // if (excludedLines && excludedLines.length > 0)
            // {
            //     let excludedClassesString = excludedLines.map(function (v) { return '.' + v }).join(',');
            //     $(excludedClassesString).css('visibility', 'hidden');
            // }
        }
    };

// }
// (window.GC = window.GC || {}, $));