import React from "react";
import Form from "react-bootstrap/Form";
import { Genders, VisitItem, GrowthChartResults, FastVisitIntents, ChartOwners } from "../../Common/Common";
import { IChartMetaDataByGender, ChartAxisNames, ChartLineTags, MappingFunctionNames, AxesSetupNames, ChartListIndexEnum, ChartItem } from "../../Common/Charts";
import { PercentileZscoreCalculator } from "../ChartComponent/GC";

// import chartList from './Charts.json';
import { ChartListLookup, SelectedChart } from "../ChartComponent/Charts";
import Dropdown from "react-bootstrap/Dropdown";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import "./ChartSelect.css";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { DisplayChartListItem } from "./DisplayChartListItem/DisplayChartListItem";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";


interface ChartSelectProps {
  gender: Genders,
  visits: VisitItem[],
  selectedChart: SelectedChart,
  updateParentState: (key: FastVisitIntents, value: any) => void
}
interface ChartSelectStates {
  // chartList:ChartItem[]
  filterByAge?: boolean,
  chartOwner?: ChartOwners,
  result?: GrowthChartResults,
  // selectedChartItem?:ChartItem<ChartListIndexEnum,ChartAxisNames,ChartAxisNames>,
  // percentile_zScore?:string
}

export class ChartList extends React.Component<ChartSelectProps, ChartSelectStates>
{
  // private pzc:PercentileZscoreCalculator = new PercentileZscoreCalculator();
  constructor(props: ChartSelectProps) {
    super(props);
    this.state = {
      filterByAge: undefined,
      chartOwner: undefined,
      result: undefined
    };
    this.handleListGroupItemClicked = this.handleListGroupItemClicked.bind(this);
  }

  handleListGroupItemClicked = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // console.log(event.currentTarget.dataset.chart_id);
    this.props.updateParentState(FastVisitIntents.selectedChart, event.currentTarget.dataset.chart_id);
    // this.setState({selectedChartItem:ChartListLookup[event.currentTarget.dataset.chart_id]})
  }
  sortedFilteredChartList = (): Array<ChartItem<ChartListIndexEnum, ChartAxisNames, ChartAxisNames>> => {
    let that = this;
    let lst = Object.values(ChartListLookup)
      .filter(function (chart) {
        return (chart.ResultType == that.state.result || that.state.result == undefined)
          && (chart.Type == that.state.chartOwner || that.state.chartOwner == undefined)
          && (that.state.filterByAge == undefined || that.state.filterByAge == false || (that.props.visits[0].age >= chart.MinAge && that.props.visits[0].age <= chart.MaxAge));
      })
      .sort(function (a, b) { return a.Type.localeCompare(b.Type) || a.ResultType.localeCompare(b.ResultType) || a.YAxis.localeCompare(b.YAxis) || a.XAxis.localeCompare(b.XAxis) })
      ;
    // .map(function(item, i){
    //     item.chartFile.then(file => {
    //         let chart: IChartMetaDataByGender<ChartAxisNames,ChartAxisNames,ChartLineTags,MappingFunctionNames,AxesSetupNames,Genders,GrowthChartResults> = {
    //           meta:file.meta,
    //           source:file.source,
    //           data:file[that.props.gender],
    //           gender:that.props.gender
    //         };
    //         that.pzc.SetChart(chart);
    //         let percentile_zScore = that.props.gender&& that.pzc.GetPercentile_ZScore_OfNative(that.props.visits[0]);

    //         return {item,percentile_zScore};
    //       });
    //     });

    return lst;
  }

  filterViewInlineForm() {
    return <InputGroup className="mb-1" size="sm">
      {/* <InputGroup.Prepend > */}

      {/* <Button variant="outline-secondary">Button</Button> */}

      <InputGroup.Text as={InputGroup.Prepend} >Filter by Age
          <InputGroup.Checkbox aria-label="filter by age" checked={this.state.filterByAge!} onChange={(evt: any) => this.setState({ filterByAge: evt.target.checked })} />
      </InputGroup.Text>
      {/* </InputGroup.Prepend> */}

      <DropdownButton
        as={InputGroup.Prepend}
        variant="outline-secondary"
        title={this.state.chartOwner || "ALL"}
        id="input-group-dropdown-1"
        onSelect={(eventKey: any, event?: any) => { this.setState({ chartOwner: eventKey || undefined }) }}>
        <Dropdown.Item eventKey={undefined} key={undefined}> <i className="fa fa-check" aria-hidden="true"></i>ALL</Dropdown.Item>
        <Dropdown.Item eventKey={ChartOwners.CDC} key={ChartOwners.CDC}> <i className="fa fa-check" aria-hidden="true"></i> {ChartOwners.CDC}</Dropdown.Item>
        <Dropdown.Item eventKey={ChartOwners.WHO} key={ChartOwners.WHO}> <i className="fa fa-check" aria-hidden="true"></i> {ChartOwners.WHO}</Dropdown.Item>
      </DropdownButton>

      <DropdownButton
        as={InputGroup.Prepend}
        variant="outline-secondary"
        title={this.state.result || "ALL"}
        id="input-group-dropdown-1"
        onSelect={(eventKey: any, event?: any) => { this.setState({ result: eventKey || undefined }) }}>
        <Dropdown.Item eventKey={undefined} key={undefined}> <i className="fa fa-check" aria-hidden="true"></i>ALL</Dropdown.Item>
        <Dropdown.Item eventKey={GrowthChartResults.percentile} key={GrowthChartResults.percentile}> <i className="fa fa-check" aria-hidden="true"></i> {GrowthChartResults.percentile}</Dropdown.Item>
        <Dropdown.Item eventKey={GrowthChartResults.zscore} key={GrowthChartResults.zscore}> <i className="fa fa-check" aria-hidden="true"></i> {GrowthChartResults.zscore}</Dropdown.Item>
      </DropdownButton>

    </InputGroup>
  }
  filterViewForm() {
    return (<Form>
      <Form.Group controlId="filterByAge">
        <Form.Check type="checkbox" label="Filter By Age" checked={this.state.filterByAge!} onChange={(evt: any) => this.setState({ filterByAge: evt.target.checked })} />
      </Form.Group>
      <ButtonToolbar className="d-flex flex-column">
        <ToggleButtonGroup type="radio"
          name="chartOwner"
          id="chartOwner"
          size="sm" className=""
          onChange={(v: any) => this.setState({ chartOwner: v })}>
          <ToggleButton value={ChartOwners.CDC} variant={(this.state.chartOwner === ChartOwners.CDC && "primary") || "secondary"}>{ChartOwners.CDC}</ToggleButton>
          <ToggleButton value={ChartOwners.WHO} variant={(this.state.chartOwner === ChartOwners.WHO && "primary") || "secondary"}>{ChartOwners.WHO}</ToggleButton>
          <ToggleButton value={undefined} variant={(this.state.chartOwner === undefined && "primary") || "secondary"}>ALL</ToggleButton>
        </ToggleButtonGroup>
      </ButtonToolbar>

      <ButtonToolbar className="d-flex flex-column mt-3">
        <ToggleButtonGroup type="radio"
          name="chartResult"
          id="chartResult"
          size="sm" className=""
          onChange={(v: any) => this.setState({ result: v })}>
          <ToggleButton value={GrowthChartResults.percentile} variant={(this.state.result === GrowthChartResults.percentile && "primary") || "secondary"}>{GrowthChartResults.percentile}</ToggleButton>
          <ToggleButton value={GrowthChartResults.zscore} variant={(this.state.result === GrowthChartResults.zscore && "primary") || "secondary"}>{GrowthChartResults.zscore}</ToggleButton>
          <ToggleButton value={undefined} variant={(this.state.result === undefined && "primary") || "secondary"}>ALL</ToggleButton>
        </ToggleButtonGroup>
      </ButtonToolbar>
    </Form>);
  }

  chartDropDownList() {

    return (<Dropdown id="dropdown-basic-button" className="mt-3 d-block d-sm-block d-md-block" drop="down">
      <Dropdown.Toggle id="dropdown-basic" className="DrowdownToggle">
        {this.props.selectedChart.selectedChartIndex &&
          <DisplayChartListItem chartItem={this.props.selectedChart.selectChartItem}
            gender={this.props.gender}
            visit={this.props.visits[0]}
            isSelected={true}></DisplayChartListItem>}
      </Dropdown.Toggle>
      <Dropdown.Menu className="scrollableChartList">
        {
          // TODO: onClick maybe will not work.
          this.sortedFilteredChartList().map((item) => {
            return <Dropdown.Item className="small"
              key={item.ChartID}
              data-chart_id={item.ChartID}
              onClick={(e:any) => this.handleListGroupItemClicked(e)}
              active={this.props.selectedChart.selectedChartIndex == item.ChartID}>

              <DisplayChartListItem chartItem={item}
                gender={this.props.gender}
                visit={this.props.visits[0]}
                isSelected={this.props.selectedChart.selectedChartIndex == item.ChartID}></DisplayChartListItem>
            </Dropdown.Item>
          })
        }

      </Dropdown.Menu>
    </Dropdown>);
  }

  chartListGroupList() {
    return (<ListGroup className="scrollableChartList mt-3 d-none d-md-block" >
      {
        this.sortedFilteredChartList().map((item) => {
          return <ListGroup.Item as="li"
            key={item.ChartID}
            data-chart_id={item.ChartID}
            onClick={this.handleListGroupItemClicked}
            active={this.props.selectedChart.selectedChartIndex == item.ChartID}>
            <DisplayChartListItem chartItem={item} gender={this.props.gender}
              visit={this.props.visits[0]}
              isSelected={this.props.selectedChart.selectedChartIndex == item.ChartID}></DisplayChartListItem>
          </ListGroup.Item>
        })
      }
    </ListGroup>);
  }
  render() {

    let that = this;
    return <div>
      <Card border="primary">
        <Card.Header className="text-center">
          {/* {this.filterViewInlineForm()} */}
          {this.filterViewForm()}

        </Card.Header>
        <Card.Body className="container-fluid">
          {/* <Card.Title className="text-center">Chart List</Card.Title> */}
          {this.chartDropDownList()}
          {/* {this.chartListGroupList()} */}
        </Card.Body>
      </Card>
    </div>
  }
}



export default ChartList;