import React, { Component ,lazy,Suspense} from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import "./FastVisit.css";
import GenderComponent from "../../components/GenderComponent/GenderComponent"
import  DateOfBirthComponent from "../../components/DateOfBirthComponent/DateOfBirthEdit";
import VisitEdit from "../../components/VisitComponent/VisitEdit";

import {Genders,VisitItem,FastVisitIntents} from "../../Common/Common";
import mergeDeep from "../../Common/Merge";
import { ChartListIndexEnum } from "../../Common/Charts";
import {  SelectedChart } from "../../components/ChartComponent/Charts";
import * as GoogleAnalytics from "../../components/GoogleAnalytics/ReactGA";

import {ChartList} from "../../components/ChartComponent/ChartSelect";
import {ChartView} from "../../components/ChartComponent/ChartView";
import Card from "react-bootstrap/Card";
//const ChartList = lazy(() => import('../../components/ChartComponent/ChartSelect'))
//const ChartView = lazy(() => import('../../components/ChartComponent/ChartView'))


type FastVisitPros = {}


type IFastVisitStates = {
    profile:{
        gender:Genders,
        dob:Date
    },
    visit:VisitItem,
    // selectedChart?:ChartListIndexEnum,
    // selectedChartItem?:ChartItem<ChartListIndexEnum,ChartAxisNames,ChartAxisNames>
    selectedChart:SelectedChart
};
class FastVisitStates implements IFastVisitStates {
    profile = {
        gender : Genders.female,
        dob: new Date()
    };
    visit:VisitItem = new VisitItem;
    selectedChart = new  SelectedChart(ChartListIndexEnum.cdc_Bmifa_2_to_20_zScore);
}

export default class FastVisit extends Component<FastVisitPros,FastVisitStates>{
    constructor(props:FastVisitPros) {
        super(props);
        
        this.state = {
            profile:{
                gender: Genders.female,
                dob: new Date()
            },
            visit:new VisitItem(),
            selectedChart: new SelectedChart(ChartListIndexEnum.cdc_Bmifa_2_to_20_percentiles)
        };

        // this.handleChange = this.handleChange.bind(this);
        this.updateStates = this.updateStates.bind(this);

        GoogleAnalytics.initializeReactGA("FastVisit");
      }

      updateStates = (key:FastVisitIntents,value:any) => 
      {
         type FastVisitStateUpdateIndex<T> = {
            [k in FastVisitIntents]:(model:T) => T
          };

          const updates:FastVisitStateUpdateIndex<FastVisitStates> = {
            [FastVisitIntents.gender]:(model:FastVisitStates) => mergeDeep(model,model.profile.gender = value),
            [FastVisitIntents.dob]:(model:FastVisitStates) => mergeDeep(model,model.profile.dob= value,model.visit.dob = value ),
            [FastVisitIntents.visitDate]:(model:FastVisitStates) => mergeDeep(model,model.visit.visitDate = value),
            [FastVisitIntents.head]:(model:FastVisitStates) => mergeDeep(model,model.visit.head = value) ,
            [FastVisitIntents.headUnit]:(model:FastVisitStates) => mergeDeep(model,model.visit.headUnit = value) ,
            [FastVisitIntents.length]:(model:FastVisitStates) => mergeDeep(model,model.visit.length = value) ,
            [FastVisitIntents.lengthUnit]:(model:FastVisitStates) => mergeDeep(model,model.visit.lengthUnit = value) ,
            [FastVisitIntents.weight]:(model:FastVisitStates) => mergeDeep(model,model.visit.weight = value) ,
            [FastVisitIntents.weightUnit]:(model:FastVisitStates) => mergeDeep(model,model.visit.weightUnit = value) ,
            [FastVisitIntents.bmiUnit]:(model:FastVisitStates) => mergeDeep(model,model.visit.bmiUnit = value) ,
            [FastVisitIntents.bmi]:(model:FastVisitStates) => model /*Do nothing becuase bmi value is auto calculated */,
            [FastVisitIntents.selectedChart]:(model:FastVisitStates) => mergeDeep(model,model.selectedChart.selectedChartIndex = value) ,
          };

         
          let states =  updates[key](this.state);
         
          this.setState(states,() => {});
      }

    render(){
        return (
        <div className="FastVisit">
            {/* <FirebaseContext.Consumer>
                {(a) => {
                
                return <div>{JSON.stringify(a)}</div>;
                }}
            </FirebaseContext.Consumer> */}
            <div className="container-fluid">
                <Row noGutters={true}>
                    {/* <Col md={2} className="border mt-3">
                        <ChartList gender={this.state.profile.gender} visits={[this.state.visit]} updateParentState={this.updateStates} selectedChart = {this.state.selectedChart}></ChartList>
                    </Col> */}
                    {/* <Col md={10}> */}
                        {/* <Row noGutters={true}> */}
                            <Col  md={3} sm={12} className="border mt-3">
                                <Card border="primary">
                                    <Card.Header className="text-center">Profile</Card.Header>
                                    <Card.Body className="">
                                        <DateOfBirthComponent dob ={this.state.profile.dob} updateParentState={this.updateStates}></DateOfBirthComponent>
                                        <GenderComponent gender={this.state.profile.gender} updateParentState={this.updateStates}></GenderComponent>
                                    </Card.Body>
                                </Card>
                                <VisitEdit updateParentState = {this.updateStates} visit={this.state.visit}></VisitEdit>
                                
                            </Col>
                            {/* <Col md={3} sm={12} className="border mt-3">
                                <ChartList gender={this.state.profile.gender} visits={[this.state.visit]} updateParentState={this.updateStates} selectedChart = {this.state.selectedChart}></ChartList>
                            </Col> */}
                            <Col md={9} sm={12} className="border mt-3">
                                <ChartList gender={this.state.profile.gender} visits={[this.state.visit]} updateParentState={this.updateStates} selectedChart = {this.state.selectedChart}></ChartList>
                      
                                {/* <Col md={2} className="border mt-3"> */}
                                {/* </Col> */}
                                <ChartView chartItem={this.state.selectedChart.selectChartItem} VisitsList={[this.state.visit]} gender= {this.state.profile.gender}></ChartView> 
                            </Col>
                        {/* </Row> */}
                    {/* </Col> */}
                </Row>
            </div>
        </div>
        );
    }
}